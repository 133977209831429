  import { Injectable, Inject } from '@angular/core';
  import { AngularFirestore } from '@angular/fire/firestore';
  import { AuthService } from '../auth/auth.service';

 
@Injectable({
    providedIn: 'root'
  })
  

  export class navigationService {
    
    types:any;
    portalSettings: any;
    constructor(
      public afs: AngularFirestore,   // Inject Firestore service
      private authService: AuthService,
      

    ) {    
        const user = this.authService.user;
        this.GetSettings(user);
    }
  
    GetSettings(user) {
      this.afs.collection('admin').doc("settings").collection("general").doc("app").valueChanges().subscribe((response) => {
      //console.log(response);
      this.portalSettings = response;
      });
    }  
  

  }
  