import { Component, NgZone } from '@angular/core';
import { Router } from "@angular/router";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {

constructor(
  public router: Router,
  public ngZone: NgZone
  
  ) {
}

ngOnInit(): void {
}

}



