import {
  from as observableFrom,
  throwError as observableThrowError,
  Observable
} from 'rxjs';

import { map, switchMap, take } from 'rxjs/operators';
import { Injectable, Inject } from '@angular/core';
import {
  AngularFirestore,
  AngularFirestoreCollection
} from '@angular/fire/firestore';
import { User } from '../../interfaces/user';
import { Timesheet } from '../../interfaces/timesheet';
// import {
//   DocumentReference,
//   CollectionReference,
//   Query
// } from '@firebase/firestore-types';
import { AuthService } from '../auth/auth.service';
import { toCommonDate } from '../../common/to-common-date';
import { SearchParams } from '../../interfaces/search-params';
import { QueryConfig } from '../../interfaces/query-config';
import { DEFAULT_QUERY_CONFIG } from '../../constants/default-query-config';
@Injectable()
export class TimesheetService {
  
  parentSheet: any;
  private timesheetCollection: AngularFirestoreCollection<Timesheet>;
  private timesheets: Observable<Array<Timesheet>>;
  private noIdMessage = 'No Sheet id provided';
  constructor(
    @Inject(DEFAULT_QUERY_CONFIG) private defaultQueryConfig: QueryConfig,
    // TODO: add config for default timesheet
    private fireDb: AngularFirestore,
    private authService: AuthService
  ) {
    this.timesheetCollection = this.fireDb.collection<Timesheet>('timesheets');
  }

  /**
   * The buildQuery function builds the query based on the user's information
   * and passed configurations based on the searchParams passed to the get function.
   * @param queryConfig the query we will make, this should be build from some of
   * the searchParams and the user's information.
   */
  private buildQuery(queryConfig: QueryConfig): any {
    // only get our timesheets
    return queryConfig.ref
      .where('createdBy.uid', '==', queryConfig.user.uid)
      .orderBy(queryConfig.orderBy || 'id') // TODO: set more sane defaults
      .startAfter(queryConfig.startAfter || 1)
      .limit(queryConfig.limit || 15);
  }
  /**
   * Gets the default timesheet we can use for the input fields.
   */
  getDefault(): Timesheet {
    return {
      weekID: '',
      dateID: '',
      sheet_date: {
      year:'',
      month: '',
      week:'',
      fullDate: toCommonDate(new Date()),
      },
      date: new Date(),
      workType: '',
      team: '',
      project: '',
      description: '',
      comments:'',
      status:'',
      hours: 0,
    };
  }
  /**
   * Gets the timesheets for the given user
   * @param user The user to get the timesheets for
   * TODO: use the user information to get the timesheets the user creates. We can update
   * this to also take in arguments for the user's roles
   */
  get(
    searchParams: SearchParams,
    update?: boolean
  ): Observable<Array<Timesheet>> {
    if (!this.timesheets || update) {
      this.timesheets = this.authService.user.pipe(
        switchMap(user => {
          console.log('test in switchMap: ', searchParams);
          return this.fireDb
            .collection<Timesheet>('timesheets', ref =>
              this.buildQuery({
                ref,
                user,
                orderBy:
                  searchParams.orderBy || this.defaultQueryConfig.orderBy,
                startAfter:
                  searchParams.startAfter || this.defaultQueryConfig.startAfter,
                limit: searchParams.limit || this.defaultQueryConfig.limit
              })
            )
            .valueChanges();
        })
      );
    }
    return this.timesheets;
  }

  // gets a single timesheet based on the id of the document.
  getOne(id: string): Observable<Timesheet> {
    return id
      ? <any>this.fireDb
          .collection<Timesheet>('timesheets')
          .doc(id)
          .valueChanges()
      : observableThrowError(new Error('No id defined'));
  }

    // gets a single timesheet based on the id of the document. (user interface)
    viewOne(id: string, user): Observable<Timesheet> {
      return id
        ? <any>this.fireDb
            .collection<Timesheet>('timesheets')
            .doc(user)
            .collection('sheets')
            .doc(id)
            .valueChanges()
        : observableThrowError(new Error('No id defined'));
    }

  /**
   * Creates the sheet in the firestore database
   * @param sheet the sheet to create
   * @param user the user we want to add the sheet for
   * TODO: add the project information argument.
   */
  create(sheet: Timesheet): Observable<void> {
    return this.authService.user.pipe(
      switchMap(user => {
        sheet.id = this.fireDb.createId();
        sheet.createdBy = {
          name: user.displayName,
          email: user.email,
          uid: user.uid
        };
        sheet.isEditable = false;
        sheet.updatedOn = new Date();
        sheet.createdOn = new Date(); 
        
        sheet.date = (sheet.date).toLocaleDateString('en-US');
        sheet.date = new Date(`${sheet.date} 12:00:00 PST`)
        return  this.timesheetCollection.doc(user.email).collection(sheet.dateID).doc(sheet.id).set(sheet, {
       // return this.timesheetCollection.doc(user.email).set(data, {  
          merge: true
        }) 
      })//
    );
  }

  /**
   * Updates the given sheet in the firestore database
   * @param sheet the sheet to update
   */
  update(sheet: Timesheet): Observable<void> {
    if (sheet.id) {
      sheet.updatedOn = new Date();
      return observableFrom(
        this.timesheetCollection.doc(sheet.id).update(sheet)
      );
    }
    return observableThrowError(new Error(this.noIdMessage));
  }

  /**
   * Removes the given sheet in the firestore database.
   * @param sheet the sheet to remove
   */
  remove(sheet: Timesheet): Observable<void> {
    if (sheet.id) {
      return observableFrom(this.timesheetCollection.doc(sheet.id).delete());
    }
    return observableThrowError(new Error(this.noIdMessage));
  }


checkDoc(user, sheet) {
  let docRef = this.fireDb
  .collection('timesheets')
  .doc(user.email)
  .collection('sheets')
  .doc(sheet.dateID);
  return docRef.ref.get().then(doc => {
   if (doc.data()) {
      return doc.data();
   } else {
       // doc.data() will be undefined in this case
         //user Data
     const userData: User = {
      role: '', 
      uid: user.uid,
      email: user.email,
      displayName: user.displayName,
      photoURL: user.photoURL,
      organization: user.organization
    }

   //new Data 
    const fields = {
      approvedBy: '',
      createdBy: userData,
      status: '',
      comments: '',
      id: sheet.dateID,
      date: toCommonDate(new Date()),
      createdOn:  toCommonDate(new Date()),
      hours: 0,
      name: sheet.weekID,
     }; 

     console.log("No such document!, trying to create it");
     docRef.set(fields).then(doc => {
     console.log("Document created");
     }
     
     ).catch(function(error) {
       console.log("Error getting document:", error);
      });
      return  fields;
   }
  })
 } 

 addHours(user, id, fields) {
  return this.fireDb
  .collection('timesheets')
  .doc(user.email)
  .collection('sheets')
  .doc(id).update(fields);
 } 

}
