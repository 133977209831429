export function  getMonths(n) {
    var m =['January','February','March','April','May','June','July','August','September','October','November','December'];
    var last_n_months =[]
    var d = new Date();
    for(var i=0;i<n;i++){
      last_n_months[i] = {
        name: m[d.getMonth()],
        year: d.getFullYear().toString(),
        full: m[d.getMonth()]+ ' - ' + d.getFullYear().toString()
      }
      d.setMonth(d.getMonth()-1);
    }
    last_n_months.unshift({full: 'All Months', name:'all'})
    return last_n_months;
  }