<div class="dashboard-panel">
  <form [formGroup]="addSheetForm">
    <mat-card class="addSheet-card">
      <div *ngIf="!sheetSaved">
        <mat-card-header>
          <mat-card-title>Create Timesheet</mat-card-title>
        </mat-card-header>
        <mat-horizontal-stepper linear #stepper>
          <mat-step [completed]="false">
            <ng-template matStepLabel>Select a week</ng-template>
            <mat-form-field>
              <mat-label>Week</mat-label>
              <mat-select [(ngModel)]="selectedValue" name="week" [ngModelOptions]="{standalone: true}" (selectionChange)="DateonChange(selectedValue)"  required>
                <mat-option *ngFor="let week of weeks" [disabled]="week.display == false"  [value]="week">
                  {{week.date}}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <div>
              <button mat-button matStepperNext type="button">Next</button>
            </div>
          </mat-step>
          <mat-step>
            <ng-template matStepLabel>Add time</ng-template>
            <mat-card-content>
              <table class="table table-border">
                <thead class="text-nowrap">
                  <th>Date</th>
                  <th>Project</th>
                  <th>Hours</th>
                  <th>Type</th>
                  <th>Description</th>
                  <th>Comments</th>
                  <th></th>
                </thead>
                <tbody>
                  <ng-container formArrayName="tableRows" *ngFor="let group of getFormControls.controls ; let i=index; let first = first">
                    <tr *ngIf="group.get('isEditable').value" [formGroupName]="i">
                      <td>
                        <mat-form-field class="input-addsheet" floatLabel="never">
                          <input matInput [matDatepicker]="picker" [value]="minDate" [min]="minDate" [max]="maxDate" formControlName="date" (click)="picker.open()" placeholder="Choose a date" readonly>
                          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                          <mat-datepicker touchUi #picker></mat-datepicker>
                        </mat-form-field> 
                        <input matInput formControlName="dateID" [hidden]="true">
                        <input matInput formControlName="weekID" [hidden]="true">
                      </td>
                      <td> 
                        
                        
                        <mat-form-field  class="input-addsheet" floatLabel="never">
                        <mat-select placeholder="Project" formControlName="project">
                          <mat-option *ngFor="let project of projects" [value]="project.name">
                            {{ project.name }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
  
                      </td>
                      <td>
  
                        <mat-form-field  class="input-addsheet" floatLabel="never">
                          <div class="number-input">
                            <button (click)="setHourValue(group, 'minus')"></button>
                            <input matInput  onKeyDown="return false" type="number"  step="0.25" min="0.25" placeholder="Hours" formControlName="hours">
                            <button (click)="setHourValue(group, 'plus')" class="plus"></button>
                            </div>
                        </mat-form-field>
  
  
                      </td>
                      <td> 
                        <mat-form-field class="input-addsheet" floatLabel="never">
                      <mat-select placeholder="Type" formControlName="type">
                      <mat-option *ngFor="let type of types" [value]="type">
                        {{ type }}
                      </mat-option>
                    </mat-select>
                    </mat-form-field>
                      </td>            
                      <td>
                        <mat-form-field floatLabel="never">
                          <input matInput placeholder="Description" formControlName="description">
                        </mat-form-field>
                        </td> 
                      <td>
                        <mat-form-field  floatLabel="never">
                        <input matInput matInput placeholder="Comments" formControlName="comments">
                      </mat-form-field>
                        </td>
  
  
                      <td>
                        <mat-icon class="delete" (click)="deleteRow(i)" *ngIf="!first" matTooltip="Remove">delete_forever</mat-icon>
                        <mat-icon class="done" (click)="doneRow(group)" *ngIf="!group.invalid">done</mat-icon>
                      </td>
                    </tr>
                    <tr  *ngIf="!group.get('isEditable').value">
                      <td>
                        {{group.get('date').value  | date:'shortDate' }}
                      </td>
                      <td>
                        {{group.get('project').value}}
                      </td>
                      <td>
                          {{group.get('hours').value}}
                      </td>
                      <td>
                          {{group.get('type').value}}
                      </td>
                      <td>
                        {{group.get('description').value}}
                      </td>
                      <td>
                        {{group.get('comments').value}}
                      </td>
                      <td>
                        <mat-icon class="edit" (click)="editRow(group)">edit</mat-icon>
                      </td>
                    </tr>
                  </ng-container>
                </tbody>
              </table>
              <div class="totalHours">Total hours for the week: <strong>{{getTotalhours()}}</strong></div>
              <div class="action-container">
                <button mat-raised-button color="warn" type="submit" (click)="addRow()"><mat-icon>add</mat-icon>Add row</button>
                <mat-slide-toggle color="primary"  [(ngModel)]="sendApproval" [ngModelOptions]="{standalone: true}">Send it for approval?</mat-slide-toggle>
  
                <button mat-raised-button [disabled]="addSheetForm.invalid" type="submit" (click)="createTimesheet(sendApproval)" color="primary">Save</button>
              </div>
            </mat-card-content>
            <div>
              <button mat-button matStepperPrevious type="button">Go Back</button>
            </div>
          </mat-step>
        </mat-horizontal-stepper>
        <mat-card-actions align="end">
          <button (click)="goBack()" mat-button type="button">
            <mat-icon>keyboard_backspace</mat-icon>Previous page
          </button>
          <!--TODO:  if project owner show other buttons here aswell!-->
        </mat-card-actions>
      </div>
      <div class="loadingCircle" *ngIf="sheetSaved">
          <mat-spinner class="inprogressCircle"></mat-spinner>
      </div>
    </mat-card>
  </form>
    <!--
      <div class="output">
        <p>Form Data:  {{addSheetForm.value | json}}</p>
          <p>Is Valid: {{addSheetForm.valid}}</p>
      </div>
    -->
</div>