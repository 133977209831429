import {Component, OnInit, ViewChild, NgZone} from '@angular/core';
import { AuthService } from '../../shared/services/auth/auth.service';
import { AngularFireAuth } from "@angular/fire/auth";
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import { ModalComponent } from '../modal/modal.component';
import { MatDialog} from '@angular/material/dialog';
import { Router } from "@angular/router";
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable, from } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { getMonths } from '../../shared/common/months';
import { AngularFireFunctions } from '@angular/fire/functions';


export interface DashboardData {
  date: string;
  project: string;
  type: string;
  description: string;
  comments:string
}


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  name: string;
  color: string;
  portalSettings: any;
  projects:any;
  selectedProject: any;
  projectHours: any;
  projectName: any;
  dashboardMonths:any;
  selectedMonth: any;
  monthName:any;
  monthHours:any;
  paymentsData: any;
  private authService: AuthService;
  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
  .pipe(
    map(result => result.matches),
    shareReplay()
  );
    
  filteredValues = { date:'', project:'', type: '', description: '', comments:'', topFilter: false}; 


constructor(
  public  fns: AngularFireFunctions,
  public afs: AngularFirestore,   // Inject Firestore service
  public afAuth: AngularFireAuth, // Inject Firebase auth service
  public dialog: MatDialog,
  public router: Router,  
  public ngZone: NgZone, // NgZone service to remove outside scope warning
  private breakpointObserver: BreakpointObserver,
  ) {

  /*     const callable = fns.httpsCallable('helloWorld');
   callable({ name: 'some-data' }).subscribe(data => {
     console.log(data)
   })
*/
  let user = JSON.parse(localStorage.getItem('user'));
  //this.GetUserTimeSheet(user); 
 // this.GetUserTimeSheets(user); 
  this.getProjects(user);   
  this.userPayment(user);
  this.dataSource.filterPredicate = this.dashboardFilterPredicate();
  this.dashboardMonths = getMonths(6);
  this.breakpointObserver.observe(['(max-width: 600px)']).subscribe(result => {
  this.displayedColumns = result.matches ? 
          ['date', 'project', 'hours'] : 
          ['date', 'project', 'hours', 'type', 'description', 'comments', 'paymentstatus'];
  });
  
  }

  getProjects(user){
    this.afs.collection('admin').doc("settings").collection('projects', ref => ref.where('team', 'array-contains', user.email)).valueChanges().subscribe((response) => {
    response.unshift({name:'All Projects'})
    this.projects = response;
     });
  }

  ProjectOnChange(p){
 /* let param = {
               where: "project",
               field: p
              };*/
   let param = ref => ref.where('project', '==', p);           
   this.projectName = p;
   let user = JSON.parse(localStorage.getItem('user'));
   if (p == 'All Projects'){
    this.GetUserTimeSheets(user); 
   }else{
    this.GetUserTimeSheetsSpecific(user, param, 'project'); 
   }
   
  }

  monthOnChange(d){
    let startDate = new Date(d.begin);
    let endDate = new Date(d.end);
    endDate.setHours(endDate.getHours() + 12);
    let param = ref => ref.where('date', '>=',  startDate).where('date', '<=',  endDate);  
    let user = JSON.parse(localStorage.getItem('user'));

    this.GetUserTimeSheetsSpecific(user, param, 'month'); 
     
    }

  openDialog(): void {
    const dialogRef = this.dialog.open(ModalComponent, {
      width: '250px',
      data: { name: this.name, color: this.color }
    });

    dialogRef.afterClosed().subscribe(res => {
      this.color = res;
    });
  }  
      // Get user info from the firebase database
 /* GetUserTimeSheet(user) {
        this.afs.collection('timesheets', ref => ref.where('createdBy.uid', '==', user.uid)).valueChanges().subscribe((response) => {
      //  console.log(response);
        this.dataSource.data = response; 
      });
    }
   */
    GetUserTimeSheets(user) {
      let data = [];
      this.afs.collection('timesheets').doc(user.email).collection('sheets', ref=> ref.where('status', '==', 'approved')).valueChanges().subscribe((response) => {
       const res = Object.keys(response).map(i => response[i]);
       res.forEach(element => {    
        this.afs.collection('timesheets').doc(user.email).collection(element.id).valueChanges().subscribe((response) => {
          const res = Object.keys(response).map(i => response[i]);
          res.forEach(element => { 
          data.push(element);  
        });     
        this.dataSource.data = data;       
        this.monthName = "All months";
        });
         })  
     });
     
   }

   GetUserTimeSheetsSpecific(user, param, mode) {
    let data = [];
    this.afs.collection('timesheets').doc(user.email).collection('sheets', ref=> ref.where('status', '==', 'approved')).valueChanges().subscribe((response) => {
     const res = Object.keys(response).map(i => response[i]);
     res.forEach(element => {    
      this.afs.collection('timesheets').doc(user.email).collection(element.id, param).valueChanges().subscribe((response) => {
        const res = Object.keys(response).map(i => response[i]);
        res.forEach(element => { 
        data.push(element);  
      });  
      this.dataSource.data = data;
      });
       })  
   });
   
 }

 dashboardFilterProject(filterValue: string) {
  this.filteredValues.topFilter = false;

  if(filterValue == 'All Projects'){
    this.filteredValues.project = '';
    this.projectName = "All Projects";  
  }
  else{
    this.filteredValues.project = filterValue.trim().toLowerCase();   
    this.projectName = filterValue.trim();  
  }
  this.dataSource.filter = JSON.stringify(this.filteredValues);

  if (this.dataSource.paginator) {
    this.dataSource.paginator.firstPage();
  }
}

 
 dashboardFilterPredicate() {
  const myFilterPredicate = function(data:DashboardData,        filter:string) :boolean {
    let searchString = JSON.parse(filter);
    let dateFound = data.date.toString().trim().toLowerCase().indexOf(searchString.date.toLowerCase()) !== -1;
    let projectFound = data.project.toString().trim().toLowerCase().indexOf(searchString.project.toLowerCase()) !== -1
    let typeFound = data.type.toString().trim().toLowerCase().indexOf(searchString.type.toLowerCase()) !== -1;
    let descriptionFound:any = data.description ? data.description.toString().trim().toLowerCase().indexOf(searchString.description.toLowerCase()) !== -1 : true;
    let commentsFound:any = data.comments? data.comments.toString().trim().toLowerCase().indexOf(searchString.comments.toLowerCase()) !== -1 : true;
    if (searchString.topFilter) {
        return projectFound  || dateFound  || typeFound  || descriptionFound  || commentsFound
    } else {
        return projectFound && dateFound && typeFound && descriptionFound && commentsFound
    }
  }
  return myFilterPredicate;
}

    /** Gets the total hours of all transactions. */
    getTotalDashboardhours() {
      return this.dataSource.filteredData.map(t => t.hours).reduce((acc, value) => acc + value, 0);
     }

     
         /** Gets the total unpaid hours for the selected period. */
    getTotalUnpaidhours() {
      return this.dataSource.filteredData.filter(h => !h.paymentstatus).map(t => t.hours).reduce((acc, value) => acc + value, 0);
      }

    GetSettings(user) {
      this.afs.collection('admin').doc("settings").valueChanges().subscribe((response) => {
     // console.log(response);
      this.portalSettings = response;
    });
  }

    //Payment history

    userPayment(user){   
      this.afs.collection('timesheets').doc(user.email).collection("payments", ref => ref.orderBy('date', 'desc')).valueChanges().subscribe(payments => {
      this.paymentsData = payments;
      });    
    }
  

  displayedColumns: string[] = ['date', 'project', 'hours', 'workType', 'description', 'comments'];
  dataSource = new MatTableDataSource([]);

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    let filter = {
      date: filterValue.trim().toLowerCase(),
      project: filterValue.trim().toLowerCase(),
      type: filterValue.trim().toLowerCase(),
      description: filterValue.trim().toLowerCase(),
      comments: filterValue.trim().toLowerCase(),
      topFilter: true
    }
    this.dataSource.filter = JSON.stringify(filter)
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }


  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;
 

  ngOnInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }
}

