import {Component, OnInit, ViewChild, NgZone} from '@angular/core';
import { AuthService } from '../../shared/services/auth/auth.service';
import { AngularFireAuth } from "@angular/fire/auth";
import { AngularFirestore} from '@angular/fire/firestore';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import { ActivatedRoute,  Router, ParamMap } from "@angular/router";
import { toCommonDate } from '../../shared/common/to-common-date';
import { User } from '../../shared/interfaces/user';
import { Location } from '@angular/common';


@Component({
  selector: 'app-timesheet-all',
  templateUrl: './timesheet-all.component.html',
  styleUrls: ['./timesheet-all.component.css']
})
export class TimesheetAllComponent implements OnInit {
  sheet_status : any;
constructor(
  private route: ActivatedRoute,
  public afs: AngularFirestore,   // Inject Firestore service
  public afAuth: AngularFireAuth, // Inject Firebase auth service
  public router: Router,  
  public ngZone: NgZone, // NgZone service to remove outside scope warning
  ) {
      let user = JSON.parse(localStorage.getItem('user'));
      this.route.paramMap.subscribe(
        (params: ParamMap) => {
          this.sheet_status = params.get('status');
          if(this.sheet_status == null  || undefined){
            this.GetUserTimeSheet(user);
          }else{
            this. GetUserTimeSheetsStatus(user, this.sheet_status);
    
          }
 
        }
      )
  }
      // Get user info from the firebase database
 /* GetUserTimeSheet(user) {
        this.afs.collection('timesheets').doc(user.email).valueChanges().subscribe((response) => {
        const res = Object.keys(response).map(i => response[i]);
        this.dataSource.data = res;
      });
    }*/

    GetUserTimeSheet(user) {
     this.afs.collection('timesheets').doc(user.email).collection('sheets').valueChanges().subscribe((response) => {
      const res = Object.keys(response).map(i => response[i]);
     // console.log(res);
      this.dataSource.data = res;
    });
  }

  GetUserTimeSheetsStatus(user, status) {
    this.afs.collection('timesheets').doc(user.email).collection('sheets', ref => ref.where('status', '==', status)).valueChanges().subscribe((response) => {
     const res = Object.keys(response).map(i => response[i]);
     this.dataSource.data = res;
   });
 }

  displayedColumns: string[] = ['date', 'name', 'hours', 'status', 'actions'];
  dataSource = new MatTableDataSource([]);

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;
 
    // AddTime
    addTime() {this.ngZone.run(() => {
       this.router.navigate(['add-time']);
        });
      }
      viewRow(index: number) {
      //  console.log(index);
        this.router.navigate([`view/${index['id']}`]);
      }

  ngOnInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }
}

