<div class="navigation-container" [class.navigation-is-mobile]="mobileQuery.matches">
  
  <mat-sidenav-container class="navigation-sidenav-container"
                         [style.marginTop.px]="mobileQuery.matches ? 56 : 0">
                         
    <mat-sidenav *ngIf="authService.userData" #drawer [mode]="mobileQuery.matches ? 'over' : 'side'"
                 [fixedInViewport]="mobileQuery.matches" fixedTopGap="56" (mouseleave)="drawer.toggle()">
      <mat-nav-list>
          <div class="nav-company"  *ngIf="navigationService.portalSettings as settings">
            <img class="nav-company-logo" routerLink="./dashboard" src="{{(settings.company.logo_url) ? settings.company.logo_url : '/assets/main_logo.png'}}"
         alt="HiView Solutions">
          </div>
          
        <a mat-list-item class="mat-list-base-menu" matTooltip="Main Dashboard" routerLink="./dashboard">
          <mat-icon class="mat-list-base-menu-icon">home</mat-icon>
          <span>Dashboard</span>
        </a>
        
        <mat-expansion-panel class="mat-elevation-z0 nav-mat-expansion-panel" dense>
          <mat-expansion-panel-header> 
          <mat-icon matListIcon class="mat-list-base-menu-icon">list_alt</mat-icon>
          <span class="nav-mat-expansion-panel-text" matTooltip="View Timesheet options">Timesheets Menu</span> </mat-expansion-panel-header>
          <mat-nav-list dense>
              <a mat-list-item class="mat-list-base-menu" routerLink="./sheets/open">
                <mat-icon matListIcon class="mat-list-base-menu-icon">border_color</mat-icon><span class="nav-mat-expansion-panel-item">Open</span></a>
              <a mat-list-item class="mat-list-base-menu" routerLink="./sheets/pending">
                <mat-icon matListIcon class="mat-list-base-menu-icon">input</mat-icon><span class="nav-mat-expansion-panel-item">Submitted</span></a>
              <a mat-list-item class="mat-list-base-menu" routerLink="./sheets/approved">
                <mat-icon matListIcon class="mat-list-base-menu-icon">done_all</mat-icon><span class="nav-mat-expansion-panel-item">Approved</span></a>
              <a mat-list-item class="mat-list-base-menu" routerLink="./sheets/rejected">
                <mat-icon matListIcon class="mat-list-base-menu-icon">backspace</mat-icon><span class="nav-mat-expansion-panel-item">Rejected</span></a>
              <a mat-list-item class="mat-list-base-menu" routerLink="./sheets">
                <mat-icon matListIcon class="mat-list-base-menu-icon">history</mat-icon><span class="nav-mat-expansion-panel-item">All</span></a>
          </mat-nav-list>
         </mat-expansion-panel>

        <a mat-list-item class="mat-list-base-menu"  matTooltip="Admin Panel"  routerLink="admin" *ngIf="authService.isAdmin">
         <mat-icon  class="mat-list-base-menu-icon" matTooltip="Admin Panel">settings</mat-icon>
         <span>Admin Panel</span>
        </a>
        <mat-divider></mat-divider>
        <a mat-list-item class="mat-list-main-menu-logout mat-list-base-menu"  matTooltip="Close session"  (click)="authService.SignOut()">
         <mat-icon class="mat-list-base-menu-icon" matTooltip="Log Out">settings_power</mat-icon>
         <span>Log Out</span>
        </a>
      </mat-nav-list>
    </mat-sidenav>

    <mat-sidenav-content [style.marginTop.px]="mobileQuery.matches ? 10 : 0">
      <mat-toolbar color="primary" class="navigation-toolbar">
        <mat-toolbar-row>
         <button mat-icon-button (click)="drawer.toggle()" *ngIf="authService.userData">
           <mat-icon>menu</mat-icon>
          </button>
          <img *ngIf="!authService.userData" class="nav-company-logo" src="../assets/logo.png"
               alt="HiView Solutions">
          <a matTooltip="Main Dashboard" class="navbar-home-link"  routerLink="./">
            <h1 class="nav-app-name" *ngIf="navigationService.portalSettings as settings">{{(settings.company.app_name) ? settings.company.app_name : 'TimeSheet'}}</h1>
          </a>
           
           <span class="toolbar-spacer"></span>
           <div *ngIf="authService.userData as user">
            <img class="nav-avatar" src="{{(user.photoURL) ? user.photoURL : '/assets/dummy-user.png'}}" alt="{{user.displayName}}">
           </div>
         </mat-toolbar-row>
         <mat-toolbar-row class="nav-toolbar-routing" *ngIf="authService.userData">
          <mat-icon class="nav-route-info-icon">apps</mat-icon> 
          <span class="nav-route-info" *ngIf="!(currentLocation == 'dashboard' || currentLocation == 'Admin Panel')"><a routerLink="dashboard">Home</a> > </span>
          <span class="nav-route-info">{{currentLocation  | titlecase }} </span>
        </mat-toolbar-row>
      </mat-toolbar>
      <!-- Add Content Here -->
      <ng-content></ng-content>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>      
<button mat-fab color="primary" class="md-fab-bottom-right"  *ngIf="authService.userData &&  donotDisplay" (click)="addTime()"  matTooltip="Create Timesheet"><mat-icon>add</mat-icon></button>