import { Component, OnInit, ViewChild, TemplateRef, Inject } from '@angular/core';
 
import { MatTable } from '@angular/material/table';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { User } from '../../shared/interfaces/user';
import { toCommonDate} from '../../shared/common/to-common-date';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireAuth } from '@angular/fire/auth';
import {MatTableDataSource} from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { Location, DOCUMENT } from '@angular/common';
import { take, subscribeOn, takeUntil, first } from 'rxjs/operators';
import { IfStmt } from '@angular/compiler';
import { TimesheetService } from '../../shared/services/timesheet/timesheet.service';
import { Observable } from 'rxjs/internal/Observable';
import {emailService} from '../../shared/services/email/email'
import {snackService} from '../../shared/services/snackbar/snack'
import { AuthService } from 'src/app/shared/services/auth/auth.service';

export interface UsersData {
  project: string;
  date: number;
}
 

@Component({
  selector: 'app-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.css']
})
export class EditComponent implements OnInit {
  timeout: any = null;
  sheetToEdit:any
  user: User;
  types:any;
  projects:any;
  minDate: Date;
  maxDate: Date;
  week: any;
  dateID: any;
  displayedColumns: string[] = ['date', 'project', 'hours', 'workType', 'description', 'comments', 'actions'];
  dataSource = new MatTableDataSource([]);
  user$: Observable<User>;
  sheetSaved: boolean = false;
 
  @ViewChild(MatTable,{static:true}) table: MatTable<any>;
  takeUntil: Observable<any>;


  @ViewChild('updateSheetStatusDialog') updateSheetStatusDialog: TemplateRef<any>;
  public updateSheetStatusDialogRef: MatDialogRef<TemplateRef<any>>;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    public emailService: emailService,
    public dialog: MatDialog,
    private route: ActivatedRoute,
    public afs: AngularFirestore,   // Inject Firestore service
    public afAuth: AngularFireAuth, // Inject Firebase auth service
    private location: Location,
    private timesheetService: TimesheetService,
    private snack : snackService,
    private authService: AuthService,
   ) {

    let sheet_id = this.route.snapshot.paramMap.get('id');
    this.user = JSON.parse(localStorage.getItem('user'));
    this.authService.currentUser.subscribe(user => {
      this.sheetSettings(user);
      this.GetUserTimeSheet(user.email, sheet_id); 
    })  
   }

   initiateForm(v?) {  
    return {
      dateID: this.dateID,
      weekID: this.week,
      date: v? v.date : this.minDate,
      project: '',
      hours: 0.25,
      type: '',
      description: '',
      comments: '',
      isEditable: true
    }
  }


   GetUserTimeSheet(user, id) {
    this.afs.collection('timesheets').doc(user).collection(id, ref => ref.orderBy('date', 'asc')).valueChanges().pipe(take(1)).subscribe((response) => {
    let res = this.dataSource.data = response;
    res.forEach(sheet => {
      sheet.date =  sheet.date.toDate();
    })
    this.week = this.dataSource.data[0].weekID;
    this.dateID = this.dataSource.data[0].dateID;
    let date = [];
    date = this.week.split("-");
    this.minDate = new Date(date[0]);
    this.maxDate = new Date(date[1]);
    this.sheetToEdit = this.dataSource.data[0].dateID;
    
  });
}

autoSaveValues(element, row_obj){
  let t = this.getTotalhours();
  clearTimeout(this.timeout);
  var $this = this;
  this.timeout = setTimeout(function () {
    if(element.dateID && element.hours && element.project && element.type){  
      //First update sheet count 
      element.row = row_obj;
      if(element.id){
        $this.updateSheetHours(element, t, 'add');
      }
      else{
        $this.timesheetService
        .create(element)
        .pipe(take(1))
        .pipe()
        .subscribe( data => {
          $this.dataSource.data.filter((value,key)=>{
            if(value.id == element.id){
              $this.dataSource.data[row_obj] = element;
              $this.updateSheetHours(element, t, 'create')
             }
           });
          },
          err => {
           console.error(err);
          });
      }
    }
  }, 5000);
}

getTotalhours() {
  return this.dataSource.filteredData.map(t => t.hours).reduce((acc, value) => acc + value, 0);
}

  addRowData(row_obj){
    var d = new Date();
    let v = this.dataSource.data[this.dataSource.data.length - 1 ];
    this.dataSource.data.push(this.initiateForm(v));
    this.table.renderRows();
  }

  updateRowData(element, row_obj){  
  let t = this.getTotalhours();
  //First update sheet count
  element.row = row_obj;
  if(element.id){
  this.updateSheetHours(element, t, 'add');
  }
  else{
     this.timesheetService
    .create(element)
    .pipe(take(1))
    .pipe()
    .subscribe( data => {
      this.dataSource.data.filter((value,key)=>{
        if(value.id == element.id){
         this.dataSource.data[row_obj] = element;
         this.updateSheetHours(element, t, 'create')
         }
       });
      },
      err => {
       console.error(err);
      });
  }

  }

  deleteRowData(element, row_obj){ 
   if(element.id){
    this.afs.collection('timesheets').doc(element.createdBy.email).collection(element.dateID).doc(element.id)
    .delete().then( res => {
      //remove element
      this.dataSource.data.splice(row_obj,1) 
      // update new count of sheet
      let t = this.getTotalhours();
      this.updateSheetHours(element, t, 'remove');
      this.table.renderRows();
    }).catch( err => {
      console.log('Error deleting document', err);
    })
  } else{

   //remove element   
   this.dataSource.data.splice(row_obj,1)
   this.table.renderRows();

  }
  }
  
  editRowData(element, row_obj){
    this.dataSource.data.filter((value,key)=>{
      if(value.id == element.id){
       this.dataSource.data[row_obj].isEditable = true;
       }
     });
  }

  setHourValue(element, row, mode){

  let value;

  if (element.hours != '0.25'){
   value =  (element.hours - 0.25);
  }else{
  value =  element.hours;
  }

  if (mode == 'plus'){
    value = (+element.hours + 0.25);
  }
  this.dataSource.data[row].hours = (value)
  }

  updateSheetHours(element, hours, mode){
    //update the hours with the new amount
    this.afs.collection('timesheets').doc(element.createdBy.email).collection('sheets').doc(element.dateID)
      .update({hours: hours}).then(res => {
        if (mode == 'add'){
          this.updateShetData(element, element.row);
        }
    })
  }


  updateShetData(element, row_obj){  
    let date = (element.date).toLocaleDateString('en-US');
        date = new Date(`${date} 12:00:00 PST`)
     this.afs.collection('timesheets').doc(element.createdBy.email).collection(element.dateID).doc(element.id)
     .update({date: date, project: element.project, hours: element.hours, type: element.type,
      description:element.description, comments: element.comments, updatedOn: new Date()}).then(res =>{
    // update element
      
       this.dataSource.data.filter((value,key)=>{
        if(value.id == element.id){
          this.dataSource.data[row_obj].isEditable = false;
        }
      });
  
      }).catch(err =>{
        console.log('Error updating document', err)
      });  
    }

    

  sheetSettings(user) {
    this.afs.collection('admin').doc("settings").valueChanges().pipe(first()).subscribe((response) => {
    this.types = response['work_type'];
    });

   // get active projects containing the user's email
   this.afs.collection('admin').doc("settings").collection('projects', ref => ref.where('status', '==', 'active').where('team', 'array-contains', user.email)).valueChanges().pipe(first()).subscribe((response) => {
    if (response){ 
      this.projects = response;
      this.projects = this.projects?.slice().sort((a, b) => a.name.localeCompare(b.name));
    }else{
      this.projects = [];
    }
    });
    // get organizations containing the user's organization
     if (user.organization instanceof Array) {
       if(user.organization.length > 0){
         for(let org of user.organization){
           this.afs.collection('admin').doc("settings").collection('projects', ref => ref.where('status', '==', 'active').where('orgs', 'array-contains', org)).valueChanges().subscribe((response) => {
             var data = this.projects;
             for (var i in response){
               var org = response[i];
               var duplicate = false;
               for (var j in data){
                 if (org.id == data[j].id){
                   duplicate = true;
                 }
               }
               if (!duplicate) {
                 this.projects.push(org);
                 this.projects = this.projects?.slice().sort((a, b) => a.name.localeCompare(b.name));
               }
                
             }
           })
         }
       }
     } else {
       if(user.organization){
         this.afs.collection('admin').doc("settings").collection('projects', ref => ref.where('status', '==', 'active').where('orgs', 'array-contains', user.organization)).valueChanges().pipe(first()).subscribe((response) => {
           var data = this.projects;
           for (var i in response){
             var org = response[i];
             var duplicate = false;
             for (var j in data){
               if (org.id == data[j].id){
                 duplicate = true;
               }
             }
             if (!duplicate) {
               this.projects.push(org);
               this.projects = this.projects?.slice().sort((a, b) => a.name.localeCompare(b.name));
             }
              
           }
         });
       }
     }

  } 

  sendForApproval(sheet): void{  
    const dialogConfig = new MatDialogConfig();
    dialogConfig.restoreFocus = false;
    dialogConfig.autoFocus = false;
    dialogConfig.role = 'dialog';
    dialogConfig.width= '400px'
    dialogConfig.data = {sheet: sheet, user: this.user, status: 'pending', action:'approval'};
    this.updateSheetStatusDialogRef = this.dialog.open(this.updateSheetStatusDialog,  dialogConfig);
  }

  updateTimeshetStatus(data){
    this.sheetSaved = true;
    this.afs.collection('timesheets').doc(data.user.email).collection('sheets').doc(data.sheet).update({status: data.status}).then(() => {
      this.snack.openSnackBar("The Timeshet has been sent for approval", "X Close");
      //send email notification
      const origin = this.document.location.origin;
      let subject = 'Timesheet submitted for approval - '+ data.user.displayName;
      let to = 'admin';
      let message =  data.user.displayName + ' sent a timesheet for approval'+
        '<p><a href="'+origin+'/admin/viewsheet/'+data.user.email+'/'+data.sheet+'">View Sheet</a></p>';
      this.emailService.emailNotification(to, subject, message).subscribe(data=> {
        //   console.log(data);
      })
      this.updateSheetStatusDialogRef.close();
      this.location.back();
    }).catch(function(error) {   
      this.updateSheetStatusDialogRef.close();
      this.sheetSaved = false;
      this.snack.openSnackBar(error, "X Close");
    })
  }


  closeupdateSheetStatusDialog(): void {
    this.updateSheetStatusDialogRef.close();
  }
    // sends the user back to the page they came from.
    goBack() {
      this.location.back();
    }

  ngOnInit(): void {
  }

}
