import {Injectable} from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
@Injectable()
export class snackService {

 constructor(public snackBar: MatSnackBar){}
    
    openSnackBar(message: string, action: string) {
        this.snackBar.open(message, action, {
           duration: 5000,
           horizontalPosition: 'right',
        });
     } 
}