<div class="dashboard-panel">
 <ng-container *ngIf="timesheet$ | async as timesheet">
          <!-- date information -->
          <mat-card>
                <h2 class="mat-h2">Timesheet Details</h2>
                <button mat-icon-button class="more-button"  *ngIf="isAdmin && (timesheet.status == 'pending' || timesheet.status == 'approved') || canEdit() && (timesheet.status == 'open' || timesheet.status == 'rejected')" [matMenuTriggerFor]="menu" aria-label="Toggle menu"  matTooltip="Click to edit">
                  <mat-icon>more_vert</mat-icon>
                </button>
                <mat-menu #menu="matMenu" xPosition="before">
                  <p><button (click)="approve(timesheet)"  mat-button color="primary" *ngIf="isAdmin && timesheet.status == 'pending'"> 
                          <mat-icon>check</mat-icon> Approve
                  </button></p>
                  
                  <button  mat-button (click)="reject(timesheet)" *ngIf="isAdmin && (timesheet.status == 'pending' || timesheet.status == 'approved') && !canEdit()" color="warn"> 
                    <mat-icon>error_outline</mat-icon> Reject
                     </button>
                  <button (click)="edit()" mat-menu-item *ngIf="canEdit() && (timesheet.status == 'open' || timesheet.status == 'rejected')">
                          <mat-icon>edit</mat-icon> Edit
                  </button>
                </mat-menu>
            <div class="div-space"></div> 
            <mat-card-content>
              <!-- first the date, time, breaktime-->
              <div fxLayout="row"
                   fxLayoutAlign="space-between center">
                   <span *ngIf="isAdmin">
                     <span class="mat-body-2">Created By:</span>
                     <span> {{timesheet.createdBy.displayName}}</span>
                   </span>
                <span>
                  <span class="mat-body-2">Creation Date:</span>
                  <span> {{timesheet.date}}</span>
                </span>
                <span>
                  <span class="mat-body-2">Week:</span>
                  <span> {{timesheet.name}}</span>
                </span>
                <span>
                  <span class="mat-body-2">Total Hours:</span>
                  <span> {{timesheet.hours}}</span>
                </span>
                <span>
                  <span class="mat-body-2">Status:</span>
                  <span> {{timesheet.status | titlecase }}</span>
                </span>
              </div>

            </mat-card-content>
            
          <mat-card-actions align="end">
            <button *ngIf="!isAdmin && canEdit()" (click)="goBack()"
            mat-button
            type="button">
            <mat-icon>keyboard_backspace</mat-icon> Go Back
           </button>
            <!--TODO:  if project owner show other buttons here aswell!-->
          </mat-card-actions>
          
              <!-- comments-->
         <mat-divider></mat-divider>
          <mat-card-content>
              <mat-expansion-panel  class="mat-elevation-z0" dense 
              (opened)="CommentsOpenState = true"
              (closed)="CommentsOpenState = false">
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    <mat-icon color="primary" >comment</mat-icon><span class="sheet-view-comments-header">  Comments ( {{timesheet.totalComments}} )</span>
                  </mat-panel-title>
                  <mat-panel-description>
                    Click here to {{CommentsOpenState ? 'hide' : 'view'}} comments
                  </mat-panel-description>
                </mat-expansion-panel-header>
                 <!-- TODO: add timesheet-comment component here-->
            <mat-list>
              <mat-list-item *ngFor="let comment of timesheet.comments; let last = last"> 
                <img matListAvatar src="{{comment.photo_url}}" /> 
                <p matLine class="sheet-commentsBox-name">{{comment.name}} 
                  <span class="sheet-commentsBox-date">on {{comment.date.toDate() | date:'medium' }}</span></p> 
                <p matLine>
                  <span class="sheet-commentsBox-text">{{comment.comment}} </span>
                </p>
      
              <mat-divider *ngIf="!last"></mat-divider>
              </mat-list-item>
            </mat-list>
            <form [formGroup]="commentsForm">
              <mat-form-field class="sheet-commentsBox">
                <mat-label>Add a comment</mat-label>
                <textarea matInput formControlName="comment" placeholder="Start typing..."></textarea>
              </mat-form-field>
              
             <div class="action-container">
            <button mat-raised-button type="submit" (click)="addComment()" color="primary" [disabled]="commentsForm.invalid" >Save</button>
         </div>
            </form>
              </mat-expansion-panel>
            
          </mat-card-content>
          </mat-card>
    </ng-container>
     <!-- TimeSheet table-->
    <div class="div-space"></div>    

    <mat-card>
                  
          <mat-form-field>
            <mat-label>Search ...</mat-label>
            <input matInput (keyup)="applyFilter($event)" placeholder="Ex. Support">
          </mat-form-field>
        <table mat-table [dataSource]="dataSource" matSortActive="date" matSortDirection="asc" matSort>
      <!-- Date Column -->
      <ng-container matColumnDef="date">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Date</th>
        <td mat-cell *matCellDef="let element"> {{element.date.toDate() | date:'M/dd/yyyy'}} </td>
      </ng-container>
      
      <!-- Project Column -->
      <ng-container matColumnDef="project">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Project</th>
        <td mat-cell *matCellDef="let element"> {{element.project}} </td>
      </ng-container>
  
      <!-- Hours Column -->
      <ng-container matColumnDef="hours">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Hours</th>
        <td mat-cell *matCellDef="let element"> {{element.hours}} </td>
      </ng-container>
  
      <!-- Type Column -->
      <ng-container matColumnDef="workType">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Work Type</th>
        <td mat-cell *matCellDef="let element"> {{element.type}} </td>
      </ng-container>
  
      <!-- Description Column -->
      <ng-container matColumnDef="description">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Description</th>
        <td mat-cell *matCellDef="let element"> {{element.description}} </td>
      </ng-container>
  
      <!-- Comments Column -->
      <ng-container matColumnDef="comments">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Comment</th>
        <td mat-cell *matCellDef="let element"> {{element.comments}} </td>
      </ng-container>
  
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  
    <mat-paginator [pageSizeOptions]="[10, 20, 50]" showFirstLastButtons></mat-paginator>
      
    <span>Total hours for the week: <strong>{{getTotalHours()}}</strong></span> 
  </mat-card>
  </div>


            <!-- Add a project Dialog template -->

            <ng-template #updateSheetStatusDialog  let-data>
              <h4 mat-dialog-title>Are you sure you want to {{data.action}} this Timesheet?</h4>
              <div mat-dialog-content>
              </div>
              <div mat-dialog-actions>
                <button mat-flat-button color="warn" (click)="closeupdateSheetStatusDialog()">Cancel</button>
                <button mat-flat-button color="primary" (click)="updateTimeshetStatus(data)" cdkFocusInitial>{{data.action  | titlecase }}</button>
              </div>
             </ng-template>