<div class="dashboard-panel">
    <mat-card>
        <mat-form-field>
            <mat-label>Search ...</mat-label>
            <input matInput (keyup)="applyFilter($event)" placeholder="Ex. Pending">
          </mat-form-field>

        <table mat-table [dataSource]="dataSource" matSort>
      <!-- Date Column -->
      <ng-container matColumnDef="date">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Created On</th>
        <td mat-cell *matCellDef="let element"> {{element.createdOn}} </td>
      </ng-container>
      
      <!-- name Column -->
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Week</th>
        <td mat-cell *matCellDef="let element"> {{element.name}} </td>
      </ng-container>
  
      <!-- Hours Column -->
      <ng-container matColumnDef="hours">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Hours</th>
        <td mat-cell *matCellDef="let element"> {{element.hours}} </td>
      </ng-container>
  
      <!-- Status Column -->
      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
        <td mat-cell *matCellDef="let element"> {{element.status  | titlecase }} </td>
      </ng-container>
  
      <!-- Comments 
      <ng-container matColumnDef="comments">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Comments</th>
        <td mat-cell *matCellDef="let element"> {{element.comment}} </td>
      </ng-container>
      Column -->

      <!-- Actions Column -->
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
        <td mat-cell *matCellDef="let element">
          <mat-icon class="view_sheet" (click)="viewRow(element)"  matTooltip="View Details">zoom_in</mat-icon>
         </td>
      </ng-container>
  
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      
      </table>
  
     <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
        
    </mat-card>
</div>