import { Component, OnInit, OnDestroy, ViewChild, NgZone, TemplateRef, Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Timesheet } from '../../shared/interfaces/timesheet';
import { User } from '../../shared/interfaces/user';
import { AuthService } from '../../shared/services/auth/auth.service';
import { TimesheetService } from '../../shared/services/timesheet/timesheet.service';
import { takeUntil, map, tap, switchMap } from 'rxjs/operators';
import { Subject, Observable } from 'rxjs';
import { Location, DOCUMENT } from '@angular/common';
import { AngularFireAuth } from "@angular/fire/auth";
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {MatDialog, MatDialogRef, MatDialogConfig} from '@angular/material/dialog';
import { FormBuilder, Validators} from '@angular/forms';
import { firestore } from 'firebase/app';
import {emailService} from '../../shared/services/email/email'
import { snackService } from 'src/app/shared/services/snackbar/snack';




@Component({
  selector: 'app-timesheet-view',
  templateUrl: './timesheet-view.component.html',
  styleUrls: ['./timesheet-view.component.css']
})
export class TimesheetViewComponent implements OnInit, OnDestroy {
  CommentsOpenState:boolean;
  comments: any;
  commentCount: any;
  portalSettings: any;
  isAdmin: boolean;
  timesheet$: Observable<Timesheet>;
  private timesheet: Timesheet;
  private user: User;
  public user_email: any;
  private takeUntil = new Subject();
  commentsForm = this.fb.group({
    comment: ['', Validators.required],
    user: [''],
  });
  @ViewChild('updateSheetStatusDialog') updateSheetStatusDialog: TemplateRef<any>;
  public updateSheetStatusDialogRef: MatDialogRef<TemplateRef<any>>;

  constructor(
    
    @Inject(DOCUMENT) private document: Document,
    public emailService: emailService,
    
    private snack : snackService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private location: Location,
    private authService: AuthService,
    private timesheetService: TimesheetService,
    public afs: AngularFirestore,   // Inject Firestore service
    public afAuth: AngularFireAuth, // Inject Firebase auth service
    public dialog: MatDialog,
    public ngZone: NgZone, // NgZone service to remove outside scope warning
  ) {
    this.authService.currentUser.subscribe( user=> {
      if (user.role == 'admin'){
        this.isAdmin = true;
      }else{
        this.isAdmin = false;
      }
    })
    
  }

  ngOnInit() {
    
    this.user = JSON.parse(localStorage.getItem('user'));  
    this.user_email = this.route.snapshot.paramMap.get('user');
    if (this.user_email === null){
      this.user_email = this.user.email;
    }
    this.timesheet$ = this.route.params.pipe(
      switchMap(params => this.timesheetService.viewOne(params['id'], this.user_email)),
      takeUntil(this.takeUntil),
      tap(timesheet => (
        
        this.timesheet = timesheet,
        this.timesheet['totalComments'] = timesheet.comments.length
        
        ))
    );
    let sheet_id = this.route.snapshot.paramMap.get('id');
    this.GetUserTimeSheet(this.user_email, sheet_id);
    
   
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;  
  }
  ngOnDestroy(): void {
    this.takeUntil.next();
    this.takeUntil.unsubscribe();
  }
  // sends the user back to the page they came from.
  goBack() {
    this.location.back();
  }
  // edits the current timesheet
  edit() {
    this.router.navigate(['edit/', this.timesheet.id]);
  }

  // returns true only if the current user can edit the timesheet
  canEdit(): boolean {
    return this.user ? this.timesheet.createdBy.uid === this.user.uid : false;
  }
  remove() {
    // TODO: add actual call todo such
  }
  approve(sheet): void{  
    const dialogConfig = new MatDialogConfig();
    dialogConfig.restoreFocus = false;
    dialogConfig.autoFocus = false;
    dialogConfig.role = 'dialog';
    dialogConfig.width= '400px'
    dialogConfig.data = {sheet: sheet.id, user: sheet.createdBy, status: 'approved', action:'approve'};
    this.updateSheetStatusDialogRef = this.dialog.open(this.updateSheetStatusDialog,  dialogConfig);
  }

  
  reject(sheet): void{  
    const dialogConfig = new MatDialogConfig();
    dialogConfig.restoreFocus = false;
    dialogConfig.autoFocus = false;
    dialogConfig.role = 'dialog';
    dialogConfig.width= '400px'
    dialogConfig.data = {sheet: sheet.id, user: sheet.createdBy, status: 'rejected', action:'reject'};
    this.updateSheetStatusDialogRef = this.dialog.open(this.updateSheetStatusDialog,  dialogConfig);
  }


   updateTimeshetStatus(data){
    this.afs.collection('timesheets').doc(data.user.email).collection('sheets').doc(data.sheet).update({status: data.status}).then(() => {
      this.snack.openSnackBar('The Timesheet has been '+data.status, "X Close");
      //send email notification

      const origin = this.document.location.origin;
      let subject = 'A timesheet you submitted has been '+data.status;
      let to =  data.user.email;
      let message =  'A timesheet you submitted has been '+data.status +
        '<p><a href="'+origin+'/view/'+data.sheet+'">View TimeSheet</a></p>';
      this.emailService.emailNotification(to, subject, message).subscribe(data=> {
     //   console.log(data);
      })

     this.updateSheetStatusDialogRef.close();
     }).catch(function(error) {   
     this.updateSheetStatusDialogRef.close();
     this.snack.openSnackBar(error, "X Close");
    })
   }

   closeupdateSheetStatusDialog(): void {
    this.updateSheetStatusDialogRef.close();
  }
      // Get user info from the firebase database
  GetUserTimeSheet(user, id) {
        this.afs.collection('timesheets').doc(user).collection(id).valueChanges().subscribe((response) => {
        this.dataSource.data = response;
      });
    }


  displayedColumns: string[] = ['date', 'project', 'hours', 'workType', 'description', 'comments'];
  dataSource = new MatTableDataSource([]);

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;
 
    // AddTime
    addTime() {this.ngZone.run(() => {
       this.router.navigate(['add-time']);
        });
      }

          // AddTime
    addComment() { 
    let sheet_id = this.route.snapshot.paramMap.get('id');
    let control = this.commentsForm.controls;
    let comment = {
      comment: control['comment'].value,
      date: new Date(),
      name: this.user.displayName,
      email: this.user_email,
      photo_url: this.user.photoURL
    }
    this.afs.collection('timesheets').doc(this.user_email).collection('sheets').doc(sheet_id).update({
      comments: firestore.FieldValue.arrayUnion(comment)
    })
    //finally clear the commentBox
    this.commentsForm.reset();
      }

      /** Gets the total hours of all dataSource. */
 
    getTotalHours() {
        return this.dataSource.data.map(t => t.hours).reduce((acc, value) => +acc + +value, 0);
      }

 
}
