import { Component, OnInit, ViewChild, Inject} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, FormArray, Validators, ControlContainer } from '@angular/forms';
import { first, switchMap } from 'rxjs/operators';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { AngularFireAuth } from "@angular/fire/auth";
import { AuthService } from '../../shared/services/auth/auth.service';
import { Timesheet } from "../../shared/interfaces/timesheet";
import { TimesheetService } from '../../shared/services/timesheet/timesheet.service';
import { Observable, Subject } from 'rxjs';
import { takeUntil, take } from 'rxjs/operators';
import { User } from '../../shared/interfaces/user';
import { getWeeks } from '../../shared/common/weeks';
import { Location, DOCUMENT } from '@angular/common';
import {emailService} from '../../shared/services/email/email'
import { snackService } from 'src/app/shared/services/snackbar/snack';

interface weeks {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-addtimesheet',
  templateUrl: './addtimesheet.component.html',
  styleUrls: ['./addtimesheet.component.css']
})

export class AddtimesheetComponent implements OnInit {
  @ViewChild('stepper') stepper;
  NextisDisabled: boolean;
  types:any;
  projects:any;
  selectedValue: any;
  sendApproval: any;
  dateID: any;
  weekID: any;
  minDate: Date;
  maxDate: Date;
  weeks: weeks[];
  addSheetForm: FormGroup;
  control: FormArray;
  mode: boolean;
  touchedRows: any;
  edit: boolean;
  timesheet: Timesheet;
  user$: Observable<User>;
  sheetSaved: boolean = false;
  
  private getWeeks(months: number): any {
    return  getWeeks(months);
  }
  private takeUntil = new Subject();
  private timesheetCollection: AngularFirestoreCollection<Timesheet>;
  constructor(
    @Inject(DOCUMENT) private document: Document,
    public emailService: emailService,
    private fb: FormBuilder,
    public afs: AngularFirestore,   // Inject Firestore service
    public afAuth: AngularFireAuth, // Inject Firebase auth service
    private location: Location,

    //new sheet service
    
    private timesheetService: TimesheetService,
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private snack : snackService,
    
    ) {
    this.authService.currentUser.subscribe(user => {
      this.sheetSettings(user);
      this.checkWeeks(user);
    }) 
    this.timesheetCollection = this.afs.collection<Timesheet>('timesheets');
    this.NextisDisabled = true;
      
     }

  ngOnInit(): void {
    this.touchedRows = [];
    this.addSheetForm = this.fb.group({
    tableRows: this.fb.array([])
    });
    this.addRow();
  }
  DateonChange(value) {
    // complete the current step
    this.stepper.selected.completed = true;
    // move to next step
    this.stepper.next();
    this.minDate = new Date(value.start);
    this.maxDate = new Date(value.end);
    this.dateID = value.id;
    this.weekID = value.date;
    const control = this.addSheetForm.get('tableRows') as FormArray;
    control.patchValue([{dateID :  value.id, weekID : value.date, date: new Date(value.start)}]);
  }
  ngAfterOnInit() {
    this.control = this.addSheetForm.get('tableRows') as FormArray;
    this.timesheet = this.timesheetService.getDefault();
  }

  initiateForm(v?): FormGroup {  
    return this.fb.group({
      dateID: [this.dateID],
      weekID: [this.weekID],
      date: [v? v.date : this.minDate, Validators.required],
      project: [null, Validators.required],
      hours: [0.25, Validators.required],
      type: [null, Validators.required],
      description: [null],
      comments: [null],
      isEditable: [true]
    });
  }

  checkWeeks(user){
  this.afs.collection('timesheets').doc(user.email).collection('sheets').valueChanges().subscribe(sheets => {
  let weeks = this.getWeeks(2)
  for(var i =0; i< sheets.length; i++ ) {
    for(var j= 0; j< weeks.length;j++) {
      if (sheets[i].name === weeks[j].date) {
        weeks[j].display = false;
      // weeks.splice(j,1);
       break;
     }
   }
  }

 /* 
  for(let week of weeks){
    if (week.display == false && week.date.startsWith('27', 2) || week.date.startsWith('28', 2)  || week.date.startsWith('29', 2)
    || week.date.startsWith('30', 2) || week.date.startsWith('31', 2)) {
      let dupWeek     = Object.assign({}, week);
      dupWeek.date    = dupWeek.date+'-2'
      dupWeek.display = true;
      weeks.push(dupWeek);
      break;
     }
  }
  */
  this.weeks = weeks;
 // console.log(weeks)

  })

  }

  addRow() {
    const control =  this.addSheetForm.get('tableRows') as FormArray;
    let v = control.value[control.value.length - 1 ];
    control.push(this.initiateForm(v));
    this.minDate;
    this.maxDate;
  }

  deleteRow(index: number) {
    const control =  this.addSheetForm.get('tableRows') as FormArray;
    control.removeAt(index);
  }

  editRow(group: FormGroup) {
    group.get('isEditable').setValue(true);
  }

  doneRow(group: FormGroup) {
    group.get('isEditable').setValue(false);
  }

  getTotalhours() {
    const control = this.addSheetForm.get('tableRows') as FormArray;
    let touched = control.controls.filter(row => row.touched).map(row => row.value);
    return touched.map(t => t.hours).reduce((acc, value) => acc + value, 0);
  }

  get getFormControls() {
    const control = this.addSheetForm.get('tableRows') as FormArray;
    return control;
  }

    
    /**
   * Creates the sheet in the firestore database
   * TODO: add the project information argument.
   */

  createSheet(sheet: Timesheet) {
  //  const control = this.addSheetForm.get('tableRows') as FormArray;
  //  this.touchedRows = control.controls.filter(row => row.touched).map(row => row.value);
  //  console.log(this.touchedRows);
    return this.authService.user.pipe(
      switchMap(user => {
        sheet.id = this.afs.createId();
        sheet.createdBy = {
          name: user['displayName'],
          email: user['email'],
          uid: user['uid']
        };
        sheet.updatedOn = new Date();
        sheet.createdOn = new Date();
        sheet.date = new Date(sheet.date);
        return this.timesheetCollection.doc(sheet.id).set(sheet, {
          merge: true
        });
      })
    );
  }

    /*
   * Creates the timesheet using the timesheet service.
   *
   * @memberof TimesheetComponent
   */
  createTimesheet(status) {
    this.sheetSaved = true;
    if (status){
      status = 'pending'
    }else{
      status = 'open';
    }
    const control = this.addSheetForm.get('tableRows') as FormArray;
    this.touchedRows = control.controls.filter(row => row.touched).map(row => row.value);
    //fist check if the shee document exists and create one if it doesn't exist.
    this.user$ = this.authService.user;
    this.user$.subscribe(user => {
      console.log(user)
      this.timesheetService.checkDoc(user, this.touchedRows[0]).then(async doc => {
        //total hours from existing doc
        let docHours = doc.hours;
        //total hours to be added from the form
        let formHours = this.touchedRows.reduce((acc, val) => +acc + +val.hours, 0);
        //total hours to be added to the DB
        let totalHours = +docHours + +formHours;
        
        //new Data
        const fields = {
          approvedBy: doc.approvedBy,
          createdBy: doc.createdBy,
          status: status,
          comments: doc.comments,
          id: doc.id,
          date: doc.createdOn,
          createdOn: doc.createdOn,
          hours: totalHours,
          name: doc.name
        };
        try{
          //first update the document data
          this.timesheetService.addHours(user, doc.id, fields);
          // add time for each of the rows
          const updated = await this.touchedRows.forEach(element => {   
            this.processSheet(element);
          })
          console.log('message', updated)
          // send email notification to admins
          if (status == 'pending'){
            const origin = this.document.location.origin;
            let subject = 'Timesheet submitted for approval - '+ user.displayName;
            let to = 'admin';
            let message =  user.displayName + ' sent a timesheet for approval'+
            '<p><a href="'+origin+'/admin/viewsheet/'+user.email+'/'+doc.id+'">View Sheet</a></p>';
            this.emailService.emailNotification(to, subject, message).subscribe(data=> {
              //   console.log(data);
              this.snack.openSnackBar('Timesheet submitted for approval', "X Close");
              setTimeout(function () {
                if(doc.id){
                  $this.router.navigate([`view/${doc.id}`]);
                }else{
                  $this.goBack();
                }
              }, 3000);
            })
          }else{
            this.snack.openSnackBar('The timesheet has been saved', "X Close");
            var $this = this;
            setTimeout(function () {
              if(doc.id){
                $this.router.navigate([`view/${doc.id}`]);
              }else{
                $this.goBack();
              }
            }, 3000);
          }
          // this.router.navigate(['/dashboard']);
        } catch (e){
          // handle errors in processing or in error.
          this.snack.openSnackBar(e, "X Close");
          this.sheetSaved = false;
          console.log(e); 
        }
      })
    })
  }

  processSheet(element){
    return this.timesheetService
      .create(element)
      .pipe(take(1))
      .pipe(takeUntil(this.takeUntil))
      .subscribe(
        () => {
          console.log('updated successfully');
          return ('updated successfully');
        },
        err => {
         console.error(err);
         return err;
        }
      );

  }

 sheetSettings(user) {
    this.afs.collection('admin').doc("settings").valueChanges().pipe(first()).subscribe((response) => {
    this.types = response['work_type'];
    });
   // get active projects containing the user's email
    this.afs.collection('admin').doc("settings").collection('projects', ref => ref.where('status', '==', 'active').where('team', 'array-contains', user.email)).valueChanges().pipe(first()).subscribe((response) => {
    this.projects = response;
    this.projects = this.projects?.slice().sort((a, b) => a.name.localeCompare(b.name));
    });
   // get organizations containing the user's organization
    if (user.organization instanceof Array) {
      if(user.organization.length > 0){
        for(let org of user.organization){
          this.afs.collection('admin').doc("settings").collection('projects', ref => ref.where('status', '==', 'active').where('orgs', 'array-contains', org)).valueChanges().subscribe((response) => {
            var data = this.projects;
            for (var i in response){
              var org = response[i];
              var duplicate = false;
              for (var j in data){
                if (org.id == data[j].id){
                  duplicate = true;
                }
              }
              if (!duplicate) {
                this.projects.push(org);
                this.projects = this.projects?.slice().sort((a, b) => a.name.localeCompare(b.name));
              }
               
            }
          })
        }
      }
    } else {
      if(user.organization){
        this.afs.collection('admin').doc("settings").collection('projects', ref => ref.where('status', '==', 'active').where('orgs', 'array-contains', user.organization)).valueChanges().pipe(first()).subscribe((response) => {
          var data = this.projects;
          for (var i in response){
            var org = response[i];
            var duplicate = false;
            for (var j in data){
              if (org.id == data[j].id){
                duplicate = true;
              }
            }
            if (!duplicate) {
              this.projects.push(org);
              this.projects = this.projects?.slice().sort((a, b) => a.name.localeCompare(b.name));
            }
             
          }
        });
      }
    }
  } 
  
  setHourValue(group: FormGroup, mode){
    let data =  group.get('hours').value;
    let value;
  
    if (data != '0.25'){
     value =  (data - 0.25);
    }else{
     value = data;
    }

    if (mode == 'plus'){
    value = (+data + 0.25);
   } group.get('hours').setValue(value);
  }

    // sends the user back to the page they came from.
    goBack() {
      this.location.back();
    }
}
