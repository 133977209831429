import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// Required components for which route services to be activated
import { SignInComponent } from '../../components/sign-in/sign-in.component';
import { DashboardComponent } from '../../components/dashboard/dashboard.component';
import { AddtimesheetComponent } from '../../components/addtimesheet/addtimesheet.component';
import { TimesheetsComponent } from '../../components/timesheets/timesheets.component';
import { TimesheetAllComponent } from '../../components/timesheet-all/timesheet-all.component';
import { TimesheetViewComponent } from '../../components/timesheet-view/timesheet-view.component';
import { AdminComponent } from '../../components/admin/admin.component';


// Import canActivate guard services
import { AuthGuard } from "../guard/auth.guard";
import { membershipGuard } from "../guard/admin.guard";
import { SecureInnerPagesGuard } from "../guard/secure-inner-pages.guard";
import { EditComponent } from '../../components/edit/edit.component';


// Include route guard in routes array
const routes: Routes = [
  { path: '', redirectTo: '/sign-in', pathMatch: 'full'},
  { path: 'sign-in', component: SignInComponent, canActivate: [SecureInnerPagesGuard]},
  { path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard] },
  { path: 'add-time', component: AddtimesheetComponent, canActivate: [AuthGuard] },
  { path: 'sheets_all', component: TimesheetsComponent, canActivate: [AuthGuard] },
  { path: 'all', component:  TimesheetAllComponent, canActivate: [AuthGuard] },
  { path: 'edit/:id', component:  EditComponent, canActivate: [AuthGuard] },
  { path: 'view/:id', component:  TimesheetViewComponent, canActivate: [AuthGuard] },
  { path: 'admin/viewsheet/:user/:id', component:  TimesheetViewComponent, canActivate: [membershipGuard], data:{role:'admin'}},
  { path: 'sheets', component:  TimesheetAllComponent, canActivate: [AuthGuard] },
  { path: 'sheets/:status', component:  TimesheetAllComponent, canActivate: [AuthGuard] },
  { path: 'admin', component:  AdminComponent, canActivate: [membershipGuard], data:{role:'admin'}},
  
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' }),
    RouterModule.forChild(routes)
            
  ],
  exports: [RouterModule]
})

export class AppRoutingModule { }