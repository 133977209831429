import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './shared/routing/app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LayoutModule } from '@angular/cdk/layout';
import { FlexLayoutModule } from '@angular/flex-layout';

// Material modules

import {A11yModule} from '@angular/cdk/a11y';
import {ClipboardModule} from '@angular/cdk/clipboard';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {PortalModule} from '@angular/cdk/portal';
import {ScrollingModule} from '@angular/cdk/scrolling';
import {CdkStepperModule} from '@angular/cdk/stepper';
import {CdkTableModule} from '@angular/cdk/table';
import {CdkTreeModule} from '@angular/cdk/tree';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatBadgeModule} from '@angular/material/badge';
import {MatBottomSheetModule} from '@angular/material/bottom-sheet';
import {MatButtonModule} from '@angular/material/button';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatCardModule} from '@angular/material/card';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatChipsModule} from '@angular/material/chips';
import {MatStepperModule} from '@angular/material/stepper';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatDialogModule} from '@angular/material/dialog';
import {MatDividerModule} from '@angular/material/divider';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatListModule} from '@angular/material/list';
import {MatMenuModule} from '@angular/material/menu';
import {MatNativeDateModule, MatRippleModule} from '@angular/material/core';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatRadioModule} from '@angular/material/radio';
import {MatSelectModule} from '@angular/material/select';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatSliderModule} from '@angular/material/slider';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatSortModule} from '@angular/material/sort';
import {MatTableModule} from '@angular/material/table';
import {MatTabsModule} from '@angular/material/tabs';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatTreeModule} from '@angular/material/tree';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

import {SatDatepickerModule, SatNativeDateModule} from 'saturn-datepicker-ext';




// Firebase services + enviorment module
import { AngularFireModule } from "@angular/fire";
import { AngularFireAuthModule } from "@angular/fire/auth";
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { environment } from '../environments/environment';


// custom services
import { AuthService } from "./shared/services/auth/auth.service";
import { navigationService } from "./shared/services/navigation/navigation.service";
import { TimesheetService } from "./shared/services/timesheet/timesheet.service";
import {
  DefaultQueryConfig,
  DEFAULT_QUERY_CONFIG
} from './shared/constants/default-query-config';

import { DisplayColumnService } from './components/timesheets/timesheet-table/display-column/display-column.service';
import { PaginationService } from './components/timesheets/timesheet-table/pagination/pagination.service';
import { emailService } from "./shared/services/email/email";


//enable HTTP request

import { HttpClientModule } from '@angular/common/http';
import { ModalComponent} from './components/modal/modal.component';

//custom modules

import { NgScrollbarModule } from 'ngx-scrollbar';
//custom components

import { SignInComponent } from './components/sign-in/sign-in.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { AddtimesheetComponent } from './components/addtimesheet/addtimesheet.component';
import { TimesheetsComponent } from './components/timesheets/timesheets.component';
import { TimesheetTableComponent } from './components/timesheets/timesheet-table/timesheet-table.component';
import { TimesheetAllComponent } from './components/timesheet-all/timesheet-all.component';
import { TimesheetViewComponent } from './components/timesheet-view/timesheet-view.component';
import { AdminComponent } from './components/admin/admin.component';
import { EditComponent } from './components/edit/edit.component';
import { NavigationComponent } from './components/navigation/navigation.component';


import { AngularFireFunctionsModule, ORIGIN } from '@angular/fire/functions';
import { snackService } from './shared/services/snackbar/snack';

 
@NgModule({
  declarations: [
    AppComponent,
    SignInComponent,
    DashboardComponent,
    ModalComponent,
    AddtimesheetComponent,
    TimesheetsComponent,
    TimesheetTableComponent,
    TimesheetAllComponent,
    TimesheetViewComponent,
    AdminComponent,
    EditComponent,
    NavigationComponent,
  ],
  imports: [
    AngularFireFunctionsModule,
    FlexLayoutModule,
    BrowserModule,
    AppRoutingModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,
    AngularFirestoreModule,
    BrowserAnimationsModule,
    LayoutModule,
    //material modules
    A11yModule,
    ClipboardModule,
    CdkStepperModule,
    CdkTableModule,
    CdkTreeModule,
    DragDropModule,
    MatAutocompleteModule,
    MatBadgeModule,
    MatBottomSheetModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatStepperModule,
    MatDatepickerModule,
    MatDialogModule,
    MatDividerModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatTreeModule,
    FormsModule,
    PortalModule,
    ScrollingModule,
    //htttp Client
    HttpClientModule,
    ReactiveFormsModule,
    SatDatepickerModule, 
    SatNativeDateModule,
    NgScrollbarModule.withConfig({
      // ...
   }),
  ],
  providers: [
    snackService,
    emailService,
    AuthService,
    navigationService,
    TimesheetService,
    DisplayColumnService, 
    PaginationService,
    {
      provide: DEFAULT_QUERY_CONFIG,
      useValue: DefaultQueryConfig
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
