import {Injectable} from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { AngularFirestore } from '@angular/fire/firestore';

const BASE_API_URL = 'https://us-central1-timesheet-f27f3.cloudfunctions.net';
const httpOptions = {
    headers: new HttpHeaders({ 'Access-Control-Allow-Methods':'GET, POST', responseType: 'text', 'Access-Control-Allow-Origin': '*'})
}

@Injectable()
export class emailService {

    email: any;
    constructor(
        public http: HttpClient,
        public afs: AngularFirestore,   // Inject Firestore service
        ){
       this.afs.collection('admin').doc('settings').collection('general').doc('notifications').valueChanges().subscribe(data => {
        this.email = data['email'];
      //  console.log(this.email)
       })
     }
    
    emailNotification(to, subject, message){
        if(to == 'admin'){
         to = this.email;
        }
        let body = new HttpParams();
        body = body.set('to', to);
        body = body.set('message', message);
        body = body.set('subject', subject);
        return this.http.post(BASE_API_URL+'/emailNotification ',body, httpOptions);
    }

}