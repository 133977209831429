<div class="dashboard-panel-admin mat-elevation-z2"> 
    <!-- backgroundColor="primary" -->    
    <mat-tab-group> 
     
        <!-- Dashboard tab -->
      <mat-tab> 
        <ng-template mat-tab-label>
       <mat-icon>dashboard</mat-icon> <span class="mat-header-tabs-header">Dashboard</span>
     </ng-template>
         
         
       
 <div class="dashboard-divider"></div>
 <h2>Total approved hours per user</h2>
 <div class="dashboard-divider"></div>
     <!-- Date vs user reports -->

     <mat-grid-list cols="3" rowHeight="120px">
  
      <mat-grid-tile [colspan]="(isHandset$ | async)? 2 :1 " [rowspan]="1">
        <mat-card class="admin-dashboard-card dashboard-card-green">
          <mat-card-header>
            <div class="admin-dashboard-actions"> 
              <mat-label class="mat-label-header"><span>Choose a date range</span></mat-label>
              <input matInput
                    placeholder="Date range"
                    [satDatepicker]="picker"
                    [value]="SelectedDate"  class="mat-select" [ngModel]="SelectedDate" (ngModelChange)="dateDetailOnChange($event)">
              <div class="date-input">
                <sat-datepicker #picker [rangeMode]="true"></sat-datepicker>
                <sat-datepicker-toggle matSuffix [for]="picker"></sat-datepicker-toggle>
              </div>
            </div>
          </mat-card-header>
          <mat-card-content class="dashboard-card-content"> 
           <!-- <div><mat-icon>av_timer</mat-icon><span> Total hours:  {{monthHours}}</span></div> -->
            <div class="dashboard-nav-icon">
              <mat-icon>calendar_today</mat-icon>
            </div>
          </mat-card-content>
        </mat-card>
      </mat-grid-tile>

      <!-- Users grid -->
      <mat-grid-tile [colspan]="(isHandset$ | async)? 2 :1 " [rowspan]="1">
        <mat-card class="admin-dashboard-card dashboard-card-blue">
          <mat-card-header>
            <div class="admin-dashboard-actions"> 
              <mat-label class="mat-label-header"><span>Select a user</span></mat-label>
              <mat-select placeholder="Select a user" (selectionChange)="userOnChange($event.value)">
                  <mat-option class="admin-select-user"  *ngFor="let users of perHourUsers" [value]="users">
                    <img src="{{users.photoURL}}">
                   {{users.displayName}} 
                  </mat-option>
                </mat-select>
          </div>
          </mat-card-header>
          <mat-card-content class="dashboard-card-content">
         <!-- <div><mat-icon>av_timer</mat-icon><span> Total hours:  {{projectHours}}</span></div> -->
            <div class="dashboard-nav-icon">
              <mat-icon>person</mat-icon>
            </div>
          </mat-card-content>
        </mat-card>
      </mat-grid-tile>
      <mat-grid-tile [colspan]="(isHandset$ | async)? 2 :1 " [rowspan]="1">
        <mat-card class="admin-dashboard-card dashboard-card-black">
          <mat-card-header>
            <div class="admin-dashboard-actions"> 
              <mat-label class="mat-label-header"><span>Filter by project</span></mat-label>
            <mat-select class="dashboard-selected-project" placeholder="All Projects" (selectionChange)="filterProjects($event.value)">
              <mat-option *ngFor="let project of projects" [value]="project.name">
                {{ project.name }}
              </mat-option>
            </mat-select>
          </div>
          </mat-card-header>
          <mat-card-content class="dashboard-card-content">
         <!--   <div><mat-icon>av_timer</mat-icon><span> Total hours:  {{projectHours}}</span></div> -->
            <div class="dashboard-nav-icon">
              <mat-icon>business</mat-icon>
            </div>
          </mat-card-content>
        </mat-card>
      </mat-grid-tile>
  
  
    </mat-grid-list>
    <mat-form-field>
      <mat-label>Search Projects ...</mat-label>
       <input matInput (keyup)="applyFilterHourDetail($event)" placeholder="Ex. HiView Internal">
    </mat-form-field>
    <div class="div-space"></div>
    <div class="export-container">
      <button mat-raised-button color="primary" (click)="exportHours()">Export Data</button>
    </div>
    <div class="div-space"></div>
    <table id="exportHours" mat-table #detailSort="matSort" [dataSource]="detailDataSource" matSortActive="date" matSortDirection="asc" matSort >
    
    <!-- User Column -->
    <ng-container matColumnDef="createdBy">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="admin-column">User </th>
      <td mat-cell *matCellDef="let element" class="admin-column"> {{element.createdBy.name}} </td>
      <td mat-footer-cell *matFooterCellDef> </td>
    </ng-container>
    <!-- Date Column -->
    <ng-container matColumnDef="date">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="admin-column">Date</th>
      <td mat-cell *matCellDef="let element" class="admin-column"> {{element.date.toDate() | date:'M/dd/yyyy'}} </td>
      <td class="table-info-row" mat-footer-cell *matFooterCellDef>Total approved hours</td>
    </ng-container>

<!-- Project Column -->
<ng-container matColumnDef="project">
  <th mat-header-cell *matHeaderCellDef mat-sort-header class="admin-column">Project</th>
  <td mat-cell *matCellDef="let element" class="admin-column"> {{element.project}} </td>
  <td mat-footer-cell *matFooterCellDef> </td>
</ng-container>

<!-- Hours Column -->
<ng-container matColumnDef="hours">
  <th mat-header-cell *matHeaderCellDef mat-sort-header class="admin-column">Hours</th>
  <td mat-cell *matCellDef="let element" class="admin-column"> {{element.hours}} </td>
  <td class="table-info-row" mat-footer-cell *matFooterCellDef>{{getTotalDashboardhours()}}</td>
</ng-container>

<!-- Type Column -->
<ng-container matColumnDef="type">
  <th mat-header-cell *matHeaderCellDef mat-sort-header class="admin-column">Work Type</th>
  <td mat-cell *matCellDef="let element" class="admin-column"> {{element.type}} </td>
  <td mat-footer-cell *matFooterCellDef> </td>
</ng-container>

<!-- Description Column -->
<ng-container matColumnDef="description">
  <th mat-header-cell *matHeaderCellDef mat-sort-header class="admin-column">Description</th>
  <td mat-cell *matCellDef="let element" class="admin-column"> {{element.description}} </td>
  <td mat-footer-cell *matFooterCellDef> </td>
</ng-container>

<!-- Comments Column -->
<ng-container matColumnDef="comments">
  <th mat-header-cell *matHeaderCellDef mat-sort-header class="admin-column">Comment</th>
  <td mat-cell *matCellDef="let element" class="admin-column"> {{element.comments}} </td>
  <td class="table-info-row" mat-footer-cell *matFooterCellDef>Total unpaid hours </td>
</ng-container>


<!-- Payment Column -->
<ng-container matColumnDef="paymentstatus">
  <th mat-header-cell *matHeaderCellDef mat-sort-header>Paid</th>
  <td mat-cell *matCellDef="let element" class="admin-disabledCheck" >
    <mat-checkbox [checked]="element.paymentstatus" [disabled]="true"></mat-checkbox>
     </td>
     <td  class="table-info-row" mat-footer-cell *matFooterCellDef>{{getTotalUnpaidhours()}} </td>
</ng-container>

<tr mat-header-row *matHeaderRowDef="DetaildisplayedColumns"></tr>
<tr mat-row *matRowDef="let row; columns: DetaildisplayedColumns;"></tr>
<tr mat-footer-row *matFooterRowDef="DetaildisplayedColumns"></tr>
</table>
<mat-paginator #detailPaginator="matPaginator" [pageSizeOptions]="[10, 20, 50]" showFirstLastButtons></mat-paginator>
<button mat-raised-button color="warn" [disabled]="getTotalUnpaidhours() < 0.25" (click)="paidHours(SelectedDate)">Update paid hours</button>
  
 <!-- Payments history -->         
 
 <div class="dashboard-divider dashboard-divider-black"></div>
 <mat-divider></mat-divider>
 <div class="dashboard-divider"></div>
 <h2>Payment History</h2>
 <div class="dashboard-divider"></div>
     <!-- Date vs user reports -->

     <mat-grid-list cols="2" rowHeight="120px">

      <!-- Users grid -->
      <mat-grid-tile [colspan]="(isHandset$ | async)? 2 :1 " [rowspan]="1">
        <mat-card class="admin-dashboard-card dashboard-card-blue">
          <mat-card-header>
            <div class="admin-dashboard-actions"> 
              <mat-label class="mat-label-header"><span>Select a user</span></mat-label>
              <mat-select placeholder="Select a user" (selectionChange)="userPayment($event.value)">
                  <mat-option class="admin-select-user"  *ngFor="let users of perHourUsers" [value]="users">
                    <img src="{{users.photoURL}}">
                   {{users.displayName}} 
                  </mat-option>
                </mat-select>
          </div>
          </mat-card-header>
          <mat-card-content class="dashboard-card-content">
         <!-- <div><mat-icon>av_timer</mat-icon><span> Total hours:  {{projectHours}}</span></div> -->
            <div class="dashboard-nav-icon">
              <mat-icon>person</mat-icon>
            </div>
          </mat-card-content>
        </mat-card>
      </mat-grid-tile>
  
  
     </mat-grid-list>
    
     <div class="dashboard-divider"></div>
     <div class="dashboard-divider"></div>
      <table mat-table #paymentsSort="matSort" [dataSource]="paymentsDataSource" matSortActive="date" matSortDirection="asc" matSort >
  
       <!-- Date Column -->
      <ng-container matColumnDef="date">
       <th mat-header-cell *matHeaderCellDef mat-sort-header class="admin-column">Date</th>
       <td mat-cell *matCellDef="let element" class="admin-column"> {{element.date.toDate() | date:'M/dd/yyyy'}} </td>
       <td mat-footer-cell *matFooterCellDef></td>
      </ng-container>

     <!-- Hours Column -->
     <ng-container matColumnDef="hours">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="admin-column">Hours</th>
      <td mat-cell *matCellDef="let element" class="admin-column"> {{element.hours}} </td>
      <td mat-footer-cell *matFooterCellDef></td>
     </ng-container>

       <!--  Amount Column -->
       <ng-container matColumnDef="amountPaid">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="admin-column">Amount paid</th>
        <td mat-cell *matCellDef="let element" class="admin-column">${{element.amountPaid}} </td>
        <td mat-footer-cell *matFooterCellDef> </td>
       </ng-container>

      <!-- Range Column -->
      <ng-container matColumnDef="range">
       <th mat-header-cell *matHeaderCellDef mat-sort-header class="admin-column">Payment period</th>
       <td mat-cell *matCellDef="let element" class="admin-column"> {{element.range}} </td>
       <td mat-footer-cell *matFooterCellDef> </td>
      </ng-container>

      <!-- id Column -->
      <ng-container matColumnDef="id">
       <th mat-header-cell *matHeaderCellDef mat-sort-header class="admin-column">Payment ID</th>
       <td mat-cell *matCellDef="let element" class="admin-column"> {{element.paymentID}} </td>
       <td mat-footer-cell *matFooterCellDef></td>
      </ng-container>



      <tr mat-header-row *matHeaderRowDef="paymentsdisplayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: paymentsdisplayedColumns;"></tr>
      <tr mat-footer-row *matFooterRowDef="paymentsdisplayedColumns"></tr>
     </table>
      <mat-paginator #paymentsPaginator="matPaginator" [pageSizeOptions]="[10, 20, 50]" showFirstLastButtons></mat-paginator>
     
      
    <div class="dashboard-divider dashboard-divider-black"></div>
   <mat-divider></mat-divider>
   <div class="dashboard-divider"></div>
   <h2>Approved hours per projects</h2>
   <div class="dashboard-divider"></div>
      <!--  Total hours vs projects-->
   <mat-grid-list cols="3" rowHeight="120px">

    <!-- Users grid -->
    <mat-grid-tile [colspan]="(isHandset$ | async)? 2 :1 " [rowspan]="1">
      <mat-card class="admin-dashboard-card dashboard-card-black">
        <mat-card-header>
          <div class="admin-dashboard-actions"> 
            <mat-label class="mat-label-header"><span>Select a user</span></mat-label>
            <mat-select placeholder="All Users" (selectionChange)="applyFilterUser($event.value)">
                <mat-option class="admin-select-user"  *ngFor="let user of adminListUsers" [value]="user.displayName">
                  <img src="{{user.photoURL}}">
                 {{user.displayName}} 
                </mat-option>
              </mat-select>
        </div>
        </mat-card-header>
        <mat-card-content class="dashboard-card-content">
       <!-- <div><mat-icon>av_timer</mat-icon><span> Total hours:  {{projectHours}}</span></div> -->
          <div class="dashboard-nav-icon">
            <mat-icon>person</mat-icon>
          </div>
        </mat-card-content>
      </mat-card>
    </mat-grid-tile>

    <mat-grid-tile [colspan]="(isHandset$ | async)? 2 :1 " [rowspan]="1">
      <mat-card class="admin-dashboard-card dashboard-card-black">
        <mat-card-header>
          <div class="admin-dashboard-actions"> 
            <mat-label class="mat-label-header"><span>Choose a date range</span></mat-label>
            <input matInput
                  placeholder="Date range"
                  [satDatepicker]="picker2"
                  [value]="SelectedDateProjects"  class="mat-select" [ngModel]="SelectedDateProjects" (ngModelChange)="monthOnChange($event)">
            <div class="date-input">
              <sat-datepicker #picker2 [rangeMode]="true"></sat-datepicker>
              <sat-datepicker-toggle matSuffix [for]="picker2"></sat-datepicker-toggle>
            </div>
          </div>
        </mat-card-header>
        <mat-card-content class="dashboard-card-content"> 
         <!-- <div><mat-icon>av_timer</mat-icon><span> Total hours:  {{monthHours}}</span></div> -->
          <div class="dashboard-nav-icon">
            <mat-icon>calendar_today</mat-icon>
       </div>
        </mat-card-content>
      </mat-card>
    </mat-grid-tile>

  <mat-grid-tile [colspan]="(isHandset$ | async)? 2 :1 " [rowspan]="1">
    <mat-card class="admin-dashboard-card dashboard-card-black">
      <mat-card-header>
        <div class="admin-dashboard-actions"> 
          <mat-label class="mat-label-header"><span>Select a project</span></mat-label>
        <mat-select class="dashboard-selected-project" placeholder="All Projects" (selectionChange)="applyFilterProject($event.value)">
          <mat-option *ngFor="let project of projects" [value]="project.name">
            {{ project.name }}
          </mat-option>
        </mat-select>
      </div>
      </mat-card-header>
      <mat-card-content class="dashboard-card-content">
     <!--   <div><mat-icon>av_timer</mat-icon><span> Total hours:  {{projectHours}}</span></div> -->
        <div class="dashboard-nav-icon">
          <mat-icon>business</mat-icon>
        </div>
      </mat-card-content>
    </mat-card>
  </mat-grid-tile>

</mat-grid-list>
 <!-- <mat-form-field>
  <mat-label>Filter</mat-label>
  <input matInput (keyup)="applyFilter($event)" placeholder="Ex. Mia">
</mat-form-field>    -->
<div class="div-space"></div>

<div class="export-container">
	<button mat-raised-button color="primary" (click)="exportTable()">Export Data</button>
</div>
<div class="div-space"></div>
<table  id="projectsExport" mat-table [dataSource]="transactions" class="mat-elevation-z8">
  <!-- Item Column -->
  <ng-container matColumnDef="user">
    <th mat-header-cell *matHeaderCellDef> User </th>
    <td mat-cell *matCellDef="let transaction"> {{transaction.user}} </td>
    <td class="table-info-row" mat-footer-cell *matFooterCellDef> Total approved hours</td>
  </ng-container>

   <!-- Project Column -->
   <ng-container matColumnDef="project">
    <th mat-header-cell *matHeaderCellDef> Project </th>
    <td mat-cell *matCellDef="let transaction"> {{transaction.project}} </td>
    <td mat-footer-cell *matFooterCellDef> </td>
  </ng-container>

  <!-- Cost Column -->
  <ng-container matColumnDef="hours">
    <th mat-header-cell *matHeaderCellDef> Hours</th>
    <td mat-cell *matCellDef="let transaction"> {{transaction.hours}} </td>
    <td  class="table-info-row" mat-footer-cell *matFooterCellDef> {{getTotalhours()}} </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="TransactionsdisplayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: TransactionsdisplayedColumns;"></tr>
  <tr mat-footer-row *matFooterRowDef="TransactionsdisplayedColumns"></tr>
 </table>
 <mat-paginator #transactionPaginator="matPaginator" [pageSizeOptions]="[10, 20, 50]" showFirstLastButtons></mat-paginator>

    </mat-tab>

       
       <!-- Users tab -->

     <mat-tab> 
      <ng-template mat-tab-label>
      <mat-icon>people</mat-icon> <span class="mat-header-tabs-header">Users</span>
      </ng-template>
        <div fxLayout="row wrap" fxLayoutGap="30px" fxFlex="100%">
          <div fxFlex.gt-xs="20%" fxFlex.lt-sm="100%" >
            <mat-card class="AdminUsers-infobar" fxFlex="100%">
              <h2>Organizations</h2>
              <mat-divider></mat-divider>
              <mat-selection-list [multiple]="false" (selectionChange)="applyFilterOrgs($event.option.value)">
                <mat-list-option *ngFor="let org of organizations" [value]="org"  [selected]="org.selected">
                  {{org.displayName}}
                </mat-list-option>
              </mat-selection-list>
              <div class="admin-mat-org-actions">
                <button (click)="removeOrg(organizations)" mat-icon-button>
                  <mat-icon matTooltip="Remove Organizations" class="hover-red">remove</mat-icon>
                </button>
                <button (click)="addOrg()" mat-icon-button>
                  <mat-icon matTooltip="Add Organizations" class="hover-blue">add</mat-icon>
                </button>
              </div>
              
            </mat-card>
          </div>

    

          <div fxFlex.gt-xs="75%" fxFlex.lt-sm="100%" >
           <div class="AdminUsersGridList">
            <table mat-table [dataSource]="adminUsers"  multiTemplateDataRows>
              <ng-container matColumnDef="{{column}}" *ngFor="let column of usersToDisplay">
                <th mat-header-cell *matHeaderCellDef> <strong>Active Users</strong> </th>
                <td mat-cell *matCellDef="let user" class="admin-users-list">
                <img class="admin-users-list-avatar" src="{{user.photoURL}}" alt="{{user.displayName}}">
                <span class="admin-users-name">  {{user.displayName}} </span>
                 <p><span> {{user.email}} </span></p>
           
              </ng-container>
       
         <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
         <ng-container matColumnDef="expandedDetail">
            
           <td mat-cell *matCellDef="let element; let user; let i = dataIndex" [attr.colspan]="usersToDisplay.length">
             <div class="admin-projects-detail"
                  [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
               <div class="admin-orgs-users-info">
                 <mat-list>
                  <mat-list-item *ngFor="let org of user.organization; let last = last">
                          <span> {{org}} </span>
                      <div class="admin-settings-orgs-actions"><mat-icon matTooltip="Remove user from org" (click)="remOrg(org, user)" class="hover-red">remove_circle_outline</mat-icon></div>
                      <mat-divider *ngIf="!last"></mat-divider>
                  </mat-list-item>
                </mat-list>
               </div>
               
               <div class="admin-projects-description-actions"> 
             <button mat-stroked-button (click)="changeOrg(organizations, user)">Add user to an Organization</button>
              </div>
             </div>
           </td>
         </ng-container>
       
         <tr mat-header-row *matHeaderRowDef="usersToDisplay"></tr>
         <tr mat-row *matRowDef="let element; columns: usersToDisplay"
             class="admin-projects-row"
             [class.projects-expanded-row]="expandedElement === element"
             (click)="expandedElement = expandedElement === element ? null : element">
         </tr>
         <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="projects-detail-row"></tr>
       </table>
        <mat-paginator #usersPaginator="matPaginator" [pageSize]="5" showFirstLastButtons></mat-paginator>
        </div>
      </div>

    </div>
     
     </mat-tab>
     <!-- Projects tab -->
    <mat-tab>
      
      <div class="admin-projects-actions">
        <button mat-stroked-button color="primary" matTooltip="Add a user to this project" (click)="addProject()">
          <mat-icon>create_new_folder</mat-icon> Add a project</button>
      </div>
     <ng-template mat-tab-label>
    <mat-icon>folder_shared</mat-icon> <span class="mat-header-tabs-header">Projects</span>
  </ng-template>
  <mat-form-field class="search-box">
      <mat-label>Search for projects</mat-label>
      <input matInput (keyup)="projectsFilter($event)" placeholder="Ex. Kronos" #input>
      <mat-icon matSuffix color="primary">search</mat-icon>
  </mat-form-field>   
   <table mat-table [dataSource]="projectsSource" multiTemplateDataRows  class="mat-elevation-z8 admin-projects-table">
    <ng-container matColumnDef="{{column}}" *ngFor="let column of columnsToDisplay">
     <th mat-header-cell *matHeaderCellDef> {{column | titlecase}} </th>
      <td mat-cell *matCellDef="let element"> {{element[column]}} </td>
     </ng-container>
 
   <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
   <ng-container matColumnDef="expandedDetail">
      
     <td mat-cell *matCellDef="let element; let i = dataIndex" [attr.colspan]="columnsToDisplay.length">
       <div class="admin-projects-detail"
            [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
         <div class="admin-projects-description">   
          <h2 matLine> Users</h2>
          <mat-divider></mat-divider>
            <mat-list>
                <mat-list-item *ngFor="let user of element.team; let last = last">
                  <img matListAvatar src="{{user.photoURL}}" alt="{{user.displayName}}">
                  <h3 matLine> {{user.displayName}} </h3>
                  <p matLine>
                    <span> {{user.email}} </span>
                  </p>
                  <div class="admin-project-item-actions"><mat-icon matTooltip="Remove {{user.displayName}} from this project" class="hover-red" (click)="removeUserProject(user, element, i)">delete</mat-icon></div>
                 <mat-divider *ngIf="!last"></mat-divider>
                </mat-list-item>
              </mat-list>
         </div>
         
         <div class="admin-projects-description admin-projects-description-orgs">    
          <h2 matLine> Organizations</h2>
          <mat-divider></mat-divider>
           <mat-list>
              <mat-list-item *ngFor="let org of element.orgs; let last = last">
                <h3 matLine> {{org.displayName}} </h3>
                <p matLine>
                  <span> {{org.id}} </span>
                </p>
                <div class="admin-project-item-actions"><mat-icon class="hover-red" matTooltip="Remove {{org.displayName}} from this project" (click)="removeOrgProject(org, element, i)">delete</mat-icon></div>
               <mat-divider *ngIf="!last"></mat-divider>
              </mat-list-item>
            </mat-list>
          </div>
         
         <div class="admin-projects-description-actions">
          <button m mat-mini-fab color="primary" matTooltip="Add a user to this project" (click)="addUsertoProject(i)"><mat-icon>person_add</mat-icon></button>
          <p class="admin-projects-description-actions-p">
          <button mat-mini-fab matTooltip="Add an organization to this project" (click)="addOrgtoProject(i)"><mat-icon>playlist_add</mat-icon></button>
            </p> 
             <p class="admin-projects-description-actions-p" *ngIf="element && element.status == 'active'">
              <button mat-mini-fab color="warn" matTooltip="Archive Project" (click)="archiveProject(element, i)"><mat-icon>archive</mat-icon></button>
            </p>
            <p class="admin-projects-description-actions-p"  *ngIf="element && element.status == 'archived'">
             <button mat-mini-fab color="warn" matTooltip="Unarchive Project" (click)="unarchiveProject(element, i)"><mat-icon>unarchive</mat-icon></button>
           </p>
        </div>
       </div>
     </td>
   </ng-container>
 
   <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
   <tr mat-row *matRowDef="let element; columns: columnsToDisplay"
       class="admin-projects-row"
       [class.projects-expanded-row]="expandedElement === element"
       (click)="expandedElement = expandedElement === element ? null : element">
   </tr>
   <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="projects-detail-row"></tr>
 </table>
        

   <mat-paginator #projectsPaginator="matPaginator" [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
   
    
     </mat-tab>


     <!-- TimeSheets tab -->

    <mat-tab>
     <ng-template mat-tab-label>
      <mat-icon>toc</mat-icon><span class="mat-header-tabs-header"> Timesheets</span>
    </ng-template>
    <mat-card>
      <mat-divider></mat-divider>
      <div class="dashboard-divider"></div>
      <h2>Pending Approval</h2>
      <div class="dashboard-divider"></div>
      <mat-form-field>
          <mat-label>Search ...</mat-label>
          <input matInput (keyup)="applyFilterPending($event)" placeholder="Ex. HiView Admin">
        </mat-form-field>

      <table mat-table #sheetPendingSort="matSort" [dataSource]="sheetSourcePending" matSort>
        <!-- User Column -->
        <ng-container matColumnDef="user">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Created By</th>
          <td mat-cell *matCellDef="let element"> {{element.user}} </td>
        </ng-container>
    <!-- Date Column -->
    <ng-container matColumnDef="date">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Created On</th>
      <td mat-cell *matCellDef="let element"> {{element.createdOn}} </td>
    </ng-container>
    
    <!-- name Column -->
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Week</th>
      <td mat-cell *matCellDef="let element"> {{element.name}} </td>
    </ng-container>

    <!-- Hours Column -->
    <ng-container matColumnDef="hours">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Hours</th>
      <td mat-cell *matCellDef="let element"> {{element.hours}} </td>
    </ng-container>

    <!-- Status Column -->
    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
      <td mat-cell *matCellDef="let element"> {{element.status  | titlecase }} </td>
    </ng-container>

    <!-- Comments 
    <ng-container matColumnDef="comments">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Comments</th>
      <td mat-cell *matCellDef="let element"> {{element.comment}} </td>
    </ng-container>
    Column -->

    <!-- Actions Column -->
    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
      <td mat-cell *matCellDef="let element">
        <mat-icon class="view_sheet" (click)="viewSheet(element)"  matTooltip="View Details">zoom_in</mat-icon>
       </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="sheetdisplayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: sheetdisplayedColumns;"></tr>
    
    </table>

   <mat-paginator #sheetPendingPaginator="matPaginator" [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
    
 <div class="dashboard-divider dashboard-divider-black"></div>
 <mat-divider></mat-divider>
 <div class="dashboard-divider"></div>
 <h2>Search Archived</h2>
 <div class="dashboard-divider"></div>
 <mat-grid-list cols="3" rowHeight="120px">
  
  <mat-grid-tile [colspan]="(isHandset$ | async)? 2 :1 " [rowspan]="1">
   <mat-form-field appearance="fill">
     <mat-label>Choose a date range</mat-label>
     <input matInput
                placeholder="Date range"
                [satDatepicker]="picker3"
                [value]="SelectedDateSheets"  class="mat-select" [ngModel]="SelectedDateSheets" (ngModelChange)="sheetDetailOnChange($event)">
          <sat-datepicker #picker3 [rangeMode]="true"></sat-datepicker>
            <sat-datepicker-toggle matSuffix [for]="picker3"></sat-datepicker-toggle>
      </mat-form-field>
  </mat-grid-tile>

  <!-- User selection -->
  <mat-grid-tile [colspan]="(isHandset$ | async)? 2 :1 " [rowspan]="1">
    <mat-form-field appearance="fill">
      <mat-label>Select a user</mat-label>
          <mat-select placeholder="Select a user" (selectionChange)="sheetOnChange($event.value)">
              <mat-option class="admin-select-user"  *ngFor="let users of sheetUsers" [value]="users">
                <img src="{{users.photoURL}}">
               {{users.displayName}} 
              </mat-option>
            </mat-select>
            <mat-icon matSuffix>person</mat-icon>
    </mat-form-field>
  </mat-grid-tile>
  <mat-grid-tile [colspan]="(isHandset$ | async)? 2 :1 " [rowspan]="1">
    <mat-form-field appearance="fill">
      <mat-label>Filter by Status</mat-label>
        <mat-select class="dashboard-selected-project" placeholder="All" (selectionChange)="filterSheets($event.value)">
          <mat-option *ngFor="let status of statuses" [value]="status">
            {{ status }}
          </mat-option>
        </mat-select>
        <mat-icon matSuffix>donut_large</mat-icon>
    </mat-form-field>
 </mat-grid-tile>


</mat-grid-list>
  <mat-form-field>
            <mat-label>Search ...</mat-label>
            <input matInput (keyup)="applyFilterStatus($event)" placeholder="Ex. Approved">
          </mat-form-field>

        <table mat-table #sheetSort="matSort" [dataSource]="sheetSource" matSort>
          <!-- User Column -->
          <ng-container matColumnDef="user">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Created By</th>
            <td mat-cell *matCellDef="let element"> {{element.user}} </td>
          </ng-container>
      <!-- Date Column -->
      <ng-container matColumnDef="date">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Created On</th>
        <td mat-cell *matCellDef="let element"> {{element.createdOn}} </td>
      </ng-container>
      
      <!-- name Column -->
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Week</th>
        <td mat-cell *matCellDef="let element"> {{element.name}} </td>
      </ng-container>

      <!-- Hours Column -->
      <ng-container matColumnDef="hours">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Hours</th>
        <td mat-cell *matCellDef="let element"> {{element.hours}} </td>
      </ng-container>

      <!-- Status Column -->
      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
        <td mat-cell *matCellDef="let element"> {{element.status  | titlecase }} </td>
      </ng-container>

      <!-- Comments 
      <ng-container matColumnDef="comments">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Comments</th>
        <td mat-cell *matCellDef="let element"> {{element.comment}} </td>
      </ng-container>
      Column -->

      <!-- Actions Column -->
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
        <td mat-cell *matCellDef="let element">
          <mat-icon class="view_sheet" (click)="viewSheet(element)"  matTooltip="View Details">zoom_in</mat-icon>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="sheetdisplayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: sheetdisplayedColumns;"></tr>
      
      </table>

    <mat-paginator #sheetPaginator="matPaginator" [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>  
  </mat-card>
    
     </mat-tab>
    </mat-tab-group>
</div>


     <!-- Add user to project Dialog template -->

     <ng-template #adduserDialog  let-data>
      <h1 mat-dialog-title>Add a user to {{data.project.name}}</h1>
      <div mat-dialog-content>
        <p></p>
        <mat-form-field class="admin-field-full">
          <mat-label>Select a user</mat-label>
          <mat-select placeholder="Select a user" [(ngModel)]="selectedUser">
              <mat-option class="admin-select-user"  *ngFor="let user of data.users" [value]="user">
                <img src="{{user.photoURL}}">
               {{user.displayName}} 
              </mat-option>
            </mat-select>
        </mat-form-field>
      </div>
      <div mat-dialog-actions>
        <button mat-flat-button color="warn" (click)="closeAddUserDialog()">Cancel</button>
        <button mat-flat-button color="primary" [disabled]="!selectedUser" (click)="addUserSave(selectedUser, data.project, data.index)" cdkFocusInitial>Save</button>
      </div>
     </ng-template>


          <!-- Add a project Dialog template -->

          <ng-template #addProjectDialog  let-data>
            <h1 mat-dialog-title>Create a Project</h1>
            <div mat-dialog-content>
              <p></p>
              <mat-form-field class="admin-field-full">
                <mat-label>Project Name</mat-label>
                  <input matInput placeholder="HiView Solutions" value="" [(ngModel)]="createdProject">
              </mat-form-field>
            </div>
            <div mat-dialog-actions>
              <button mat-flat-button color="warn" (click)="closeAddProjectDialog()">Cancel</button>
              <button mat-flat-button color="primary" [disabled]="!createdProject" (click)="addProjectSave(createdProject)" cdkFocusInitial>Save</button>
            </div>
           </ng-template>


                     <!-- Paid Hours Dialog template -->

          <ng-template #paidHoursDialog  let-data>
         <h2 mat-dialog-title>Update paid hours</h2>
             
            <span *ngIf="data.range" class="admin-payment-range">* {{data.range.begin  | date:'M/dd/yyyy'}} - {{data.range.end  | date:'M/dd/yyyy'}}</span>
           
            <div mat-dialog-content>
                
                <p>Unpaid hours: <strong> {{data.hours}}</strong></p>
              <mat-form-field>
                <mat-label>Enter the hourly rate</mat-label>
                  <input matInput placeholder="Enter the hourly rate" 
                  [value]="ratetopay"  (ngModelChange)="paidHoursCal($event)" [(ngModel)]="ratetopay">
              </mat-form-field>
              
              <mat-form-field>
                <mat-label>Amount to pay</mat-label>
                  <input matInput 
                  [value]="amountPaid"  [(ngModel)]="totaltopay">
              </mat-form-field>
              
            </div>
            <div mat-dialog-actions>
              <button mat-flat-button color="warn" (click)="paidHoursClose()">Cancel</button>
              <button mat-flat-button color="primary" (click)="paidHoursSave(totaltopay, data.range)" cdkFocusInitial>Save</button>
            </div>
           </ng-template>


           <!-- Add Organization Dialog template -->
 
           <ng-template #addOrgDialog  let-data>
             <h1 mat-dialog-title>Add a new Organization</h1>
             <div mat-dialog-content>
               <p></p>
               <mat-form-field class="admin-field-full">
                 <mat-label>Organization Name</mat-label>
                   <input matInput placeholder="HiView Solutions" value="" [(ngModel)]="createdOrg">
               </mat-form-field>
             </div>
             <div mat-dialog-actions>
               <button mat-flat-button color="warn" (click)="closeAddOrgDialog()">Cancel</button>
               <button mat-flat-button color="primary" [disabled]="!createdOrg" (click)="addOrgSave(createdOrg)" cdkFocusInitial>Save</button>
             </div>
            </ng-template>


            <!-- Add Organization Dialog template -->
  
            <ng-template #removeOrgDialog  let-data>
              <h1 mat-dialog-title>Remove Organizations</h1>
              <div mat-dialog-content>
                <p></p>
                <mat-form-field class="admin-field-full">
                  <mat-label>Select an organization to delete</mat-label>
                  <mat-select placeholder="Select an organization to delete" [(ngModel)]="selectedOrg">
                    <ng-container *ngFor="let org of data.organizations">
                       <mat-option class="admin-select-user" *ngIf="org.id != 'all-system-users'" [value]="org">
                        {{org.displayName}} 
                       </mat-option>
                    </ng-container>
                  </mat-select>
                </mat-form-field>
              </div>
              <div mat-dialog-actions>
                <button mat-flat-button color="primary" (click)="closeremoveOrgDialog()">Cancel</button>
                <button mat-flat-button color="warn" [disabled]="!selectedOrg" (click)="removeOrgSave(selectedOrg)" cdkFocusInitial>Delete</button>
              </div>
             </ng-template>


             <!-- Add Organization Dialog template -->
   
             <ng-template #changeOrgDialog  let-data>
               <h3 mat-dialog-title>Move {{data.user.displayName}} to a new OU</h3>
               <div mat-dialog-content>
                 <p></p>
                 <mat-form-field class="admin-field-full">
                   <mat-label>Select an organization</mat-label>
                   <mat-select placeholder="Select an organization" [(ngModel)]="selectedOrg">
                    <ng-container *ngFor="let org of data.organizations">
                       <mat-option class="admin-select-user" *ngIf="org.id != 'all-system-users'" [value]="org">
                        {{org.displayName}} 
                       </mat-option>
                    </ng-container>
                  </mat-select>
                 </mat-form-field>
               </div>
               <div mat-dialog-actions>
                 <button mat-flat-button color="primary" (click)="closechangeOrgDialog()">Cancel</button>
                 <button mat-flat-button color="warn" [disabled]="!selectedOrg" (click)="changeOrgSave(selectedOrg, data.user)" cdkFocusInitial>Save</button>
               </div>
              </ng-template>


              


             <!-- Add Organization to Project template -->
   
             <ng-template #addOrgtoProjectDialog  let-data>
              <h3 mat-dialog-title>Add Organizations to {{data.project.name}}</h3>
              <div mat-dialog-content>
                <p></p>
                <mat-form-field class="admin-field-full">
                  <mat-label>Select an organization</mat-label>
                  <mat-select placeholder="Select an organization" [(ngModel)]="selectedOrg">
                   <ng-container *ngFor="let org of data.organizations">
                      <mat-option class="admin-select-user" *ngIf="org.id != 'all-system-users'" [value]="org">
                       {{org.displayName}} 
                      </mat-option>
                   </ng-container>
                 </mat-select>
                </mat-form-field>
              </div>
              <div mat-dialog-actions>
                <button mat-flat-button color="primary" (click)="closeaddOrgtoProjectDialog()">Cancel</button>
                <button mat-flat-button color="warn" [disabled]="!selectedOrg" (click)="addOrgtoProjectSave(selectedOrg, data.project, data.index)" cdkFocusInitial>Save</button>
              </div>
             </ng-template>