import { Component, OnInit, OnDestroy } from '@angular/core';
import { TimesheetService } from '../../shared/services/timesheet/timesheet.service';
import { Timesheet } from '../../shared/interfaces/timesheet';
import { Observable, Subject, from } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AuthService } from '../../shared/services/auth/auth.service';
import { User } from '../../shared/interfaces/user';
import { SelectionModel } from '@angular/cdk/collections';


@Component({
  selector: 'app-timesheets',
  templateUrl: './timesheets.component.html',
  styleUrls: ['./timesheets.component.css']
})
export class TimesheetsComponent implements OnInit, OnDestroy {
  timesheets: Observable<Array<Timesheet>>;
  selection = new SelectionModel(true, []);
  private takeUntil = new Subject();
  constructor() {}

  ngOnInit() {}

  ngOnDestroy() {
    this.takeUntil.next();
    this.takeUntil.unsubscribe();
  }

  onSelectionChange(timesheet: Timesheet) {
    this.selection.toggle(timesheet);
  }
}
