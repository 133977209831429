import { Component, OnInit, ChangeDetectorRef, NgZone, ViewChild } from '@angular/core';
import {MediaMatcher} from '@angular/cdk/layout';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/shared/services/auth/auth.service';
import { navigationService } from 'src/app/shared/services/navigation/navigation.service';
import { filter } from 'rxjs/operators';
import { MatSidenav } from '@angular/material/sidenav';

interface Menu {
  link: string;
  name: string;
  icon: string;
  alt: string;
}

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.css']
})
export class NavigationComponent implements OnInit {
  mobileQuery: MediaQueryList;
  @ViewChild('drawer') drawer: MatSidenav;
  public menus: Menu[] = [
    {name: 'Home', link:'./dashboard', icon: 'home', alt:'Home'},
    {name: 'TimeSheet', link:'./sheets', icon: 'list_alt', alt:'Timesheets'}
  ]

  private _mobileQueryListener: () => void;
  donotDisplay: boolean;
  currentLocation:any;

  constructor(
    changeDetectorRef: ChangeDetectorRef, 
    media: MediaMatcher,
    public router: Router,  
    public ngZone: NgZone, // NgZone service to remove outside scope warning
    public authService: AuthService,
    public navigationService: navigationService,
    private route: ActivatedRoute
    ) {
    this.mobileQuery = media.matchMedia('(max-width: 600px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);

    this.donotDisplay = true;
    
    router.events.pipe(
      filter(e => e instanceof NavigationEnd)
    ).subscribe(e => {
      let currentURL = e['url'];
      if (currentURL == '/add-time' || currentURL.startsWith('/admin') || currentURL.startsWith( '/edit/')){
        this.donotDisplay = false;
      }else{
        this.donotDisplay = true;
      }
    });
  
  }

      // AddTime
      addTime() {this.ngZone.run(() => {
        this.router.navigate(['add-time']);
         });
       }
 
  ngOnInit(): void {
    this.router.events.subscribe((e) => {
      if (e instanceof NavigationEnd) {
       let url = e.url.replace(/\//, "");
       url = url.replace(/\//g, ' > ');
       url = url.replace(new RegExp("\\b"+'admin'+"\\b"),'Admin Panel');
       url = url.replace(new RegExp("\\b"+'add-time'+"\\b"),'Add Timesheet');
       url = url.replace(new RegExp("\\b"+'viewsheet'+"\\b"),'Timesheet');
       url = url.replace(new RegExp("\\b"+'view'+"\\b"),'View Timesheet');
       this.currentLocation = url;
      }
    });
  }

}
