<div class="dashboard-panel">
    <mat-card>
			<h2 class="mat-h2">Editing Week: {{week}}</h2>
			<!--	<span>Make sure you save the changes for each of the rows prior leaving this page by clicking on <mat-icon class="done">done</mat-icon></span>-->
			<div class="div-space"></div>
			<mat-table [dataSource]="dataSource" #mytable>
				<!-- Date Column -->
				<ng-container matColumnDef="date">
				<mat-header-cell *matHeaderCellDef  class="input-timeDate"> Date </mat-header-cell>
				<mat-cell *matCellDef="let element; let i=index" class="input-timeDate" >
					<mat-form-field floatLabel="never" class="input-timeDate"  *ngIf="element.isEditable">
						<input matInput [matDatepicker]="picker"  [(ngModel)]="element.date" [value]="element.date"  [min]="minDate" [max]="maxDate" (click)="picker.open()" 
						placeholder="Choose a date" readonly (ngModelChange)="autoSaveValues(element, i)" required>
						<mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
						<mat-datepicker touchUi #picker></mat-datepicker>
					</mat-form-field>
					<span class="input-timeDate" *ngIf="!element.isEditable"> {{element.date  | date:'M/dd/yyyy'}}</span>
					</mat-cell>
				</ng-container>

				<!-- Project Column -->
				<ng-container matColumnDef="project">
					<mat-header-cell *matHeaderCellDef class="input-space"> Project </mat-header-cell>
					<mat-cell *matCellDef="let element; let i=index" class="input-space">
						<mat-form-field floatLabel="never" *ngIf="element.isEditable">
							<mat-select placeholder="Project"  [(ngModel)]="element.project" (ngModelChange)="autoSaveValues(element, i)" required>
								<mat-option *ngFor="let project of projects" [value]="project.name"> 
								{{ project.name }}
								</mat-option>
							</mat-select>
						</mat-form-field>
					<span *ngIf="!element.isEditable"> {{element.project}}</span>
					</mat-cell>
				</ng-container>


				<!-- Hours Column -->
				<ng-container matColumnDef="hours">
					<mat-header-cell *matHeaderCellDef  class="input-editTime"> Hours</mat-header-cell>
					<mat-cell *matCellDef="let element; let i=index"  class="input-editTime">
						<mat-form-field floatLabel="never" *ngIf="element.isEditable"   class="input-editTime number-input-time">
							<div class="number-input">
							<button (click)="setHourValue(element, i, 'minus'); autoSaveValues(element, i)"></button>
							<input onKeyDown="return false" matInput type="number" step="0.25" min="0.25" placeholder="Hours" 
							[value]="element.hours" [(ngModel)]="element.hours" required>
							<button (click)="setHourValue(element, i, 'plus'); autoSaveValues(element, i)" class="plus"></button>
						</div>
						</mat-form-field>
						<span class="input-editTime" *ngIf="!element.isEditable"> {{element.hours}}</span>
					</mat-cell>
				</ng-container>


				<!-- WorkType Column -->
				<ng-container matColumnDef="workType">
					<mat-header-cell *matHeaderCellDef class="input-space"> Work Type </mat-header-cell>
					<mat-cell *matCellDef="let element; let i=index; let first = first" class="input-space">
						<mat-form-field floatLabel="never" *ngIf="element.isEditable">
							<mat-select placeholder="Work Type"  [(ngModel)]="element.type" (ngModelChange)="autoSaveValues(element, i)" required>
								<mat-option *ngFor="let type of types" [value]="type">
								{{ type }}
								</mat-option>
							</mat-select>
						</mat-form-field>
						<span *ngIf="!element.isEditable"> {{element.type}}</span>
					</mat-cell>
				</ng-container>

			
				<!-- description Column -->
				<ng-container matColumnDef="description">
					<mat-header-cell *matHeaderCellDef class="input-space"> Description </mat-header-cell>
					<mat-cell *matCellDef="let element; let i=index" class="input-space">
						<mat-form-field floatLabel="never" *ngIf="element.isEditable">
							<input matInput placeholder="Add a description" [value]="element.description" 
							[(ngModel)]="element.description" (ngModelChange)="autoSaveValues(element, i)">
						</mat-form-field>
						<span *ngIf="!element.isEditable"> {{element.description}}</span>
					</mat-cell>
				</ng-container>

				<!-- comments Column -->
				<ng-container matColumnDef="comments">
					<mat-header-cell *matHeaderCellDef> Comments </mat-header-cell>
					<mat-cell *matCellDef="let element; let i=index">
						<mat-form-field floatLabel="never" *ngIf="element.isEditable">
							<input matInput placeholder="Add a comment" [value]="element.comments" [(ngModel)]="element.comments" (ngModelChange)="autoSaveValues(element, i)">
						</mat-form-field>
						<span *ngIf="!element.isEditable"> {{element.comments}}</span>
					</mat-cell>
				</ng-container>
				<!-- Actions Column -->
				<ng-container matColumnDef="actions">
					<mat-header-cell *matHeaderCellDef> </mat-header-cell>
					<mat-cell *matCellDef="let element; let i=index; let first = first">
						
						<mat-icon *ngIf="!element.isEditable" class="edit" (click)="editRowData(element, i)" matTooltip="Edit this row">edit</mat-icon>
						<mat-icon *ngIf="!element.isEditable && !first" class="delete" (click)="deleteRowData(element, i)" matTooltip="Delete forever">delete_forever</mat-icon>
						<mat-icon *ngIf="element.isEditable && element.hours && element.project && element.date && element.type"
						class="done" (click)="updateRowData(element, i)" matTooltip="Save the changes">done</mat-icon>
					</mat-cell>
				</ng-container>
				<mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
				<mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
	  		</mat-table>
			<div class="totalHours">Total hours for the week: <strong>{{getTotalhours()}}</strong></div>
	  
			<div class="action-container">
				<button mat-raised-button color="warn" type="submit" (click)="addRowData({})">
				<mat-icon>add</mat-icon>Add row</button>
				<button mat-raised-button  type="button" color="primary" (click)="sendForApproval(sheetToEdit)">
					Send it for approval
					</button>
				<button mat-raised-button  type="button" (click)="goBack()">
				<mat-icon>keyboard_backspace</mat-icon> Save & Go Back
					</button>
			</div>
	</mat-card>
</div>


<!-- Approval Dialog template -->
<ng-template #updateSheetStatusDialog  let-data>
	<h4 mat-dialog-title>Are you sure you want to send this Timesheet for {{data.action}}?</h4>
	<div mat-dialog-content>
		
	</div>
	<div mat-dialog-actions>
		<button mat-flat-button color="warn" (click)="closeupdateSheetStatusDialog()">Cancel</button>
		<button mat-flat-button color="primary" (click)="updateTimeshetStatus(data)" cdkFocusInitial>Send</button>
	</div>
</ng-template>