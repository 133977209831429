import { Injectable, NgZone, ɵCompiler_compileModuleSync__POST_R3__ } from '@angular/core';
import { of as observableOf, Observable } from 'rxjs';
import { share, switchMap } from 'rxjs/operators';
import { User } from "../../interfaces/user";
import { auth } from 'firebase/app';
import { AngularFireAuth } from "@angular/fire/auth";
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { Router } from "@angular/router";
import { HttpClient } from '@angular/common/http';



@Injectable({
  providedIn: 'root'
})

export class AuthService {
 
  currentUser: Observable<User>;
  userMenu:  any;
  isAdmin:boolean;
  userData: any; // Save logged in user data
  customerDomain: any; // Save logged in user data

  constructor(
    public afs: AngularFirestore,   // Inject Firestore service
    public afAuth: AngularFireAuth, // Inject Firebase auth service
    public router: Router,  
    public ngZone: NgZone, // NgZone service to remove outside scope warning
    private http: HttpClient // HTTP requests
  ) {    

    this.currentUser = this.afAuth.authState.pipe(
      switchMap(user => {
        if (user) {
          return this.afs.doc<User>(`users/${user.uid}`).valueChanges();
        } else {
          return observableOf(null);
        }
      })
    );

    /* Saving user data in localstorage when 
    logged in and setting up null when logged out */
    this.afAuth.authState.subscribe(user => {
      if (user) {
       //this.GetUserInfo(user);
       this.GetUserInfo(user);
       localStorage.setItem('user', JSON.stringify(user));
       JSON.parse(localStorage.getItem('user'));
     } else {
       localStorage.setItem('user', null);
       JSON.parse(localStorage.getItem('user'));
     }
   })

 }


 get user(): Observable<User> {
  return this.currentUser.pipe(share());
}
getUser(): Observable<User> {
  return this.currentUser.pipe(share());
}

    // Get user info from the firebase database
    GetUserInfo(user) {
      this.afs.collection('users').doc(user.uid).valueChanges().subscribe((response) => {
      this.userMenu = true;
      if (response['role'] == 'admin'){
        this.isAdmin =true;
      }else{
        this.isAdmin = false;
      }
      this.userData = response;
    });
    
}

  // Returns true when user is looged in and email is verified
  get isLoggedIn(): boolean {
    const user = JSON.parse(localStorage.getItem('user'));
    return (user !== null) ? true : false;
    //return (user !== null && user.emailVerified !== false) ? true : false;
    //removed to allow unverified email address
  }

 
  GoogleAuth() {
    return this.AuthLogin(new auth.GoogleAuthProvider());
  }

  // Auth logic to run auth providers
  AuthLogin(provider) {
    return this.afAuth.auth.signInWithPopup(provider)
    .then((result) => { 
     // Check if he user already exist to get the role
    this.afs.collection('users', ref => ref.where('email', '==', result.user.email)).valueChanges().subscribe(user => { 
    if (user[0] == null){
    user[0] = result.user;
    user[0]['role'] = 'member';
    }  
    this.SetUserData(user[0]).then(() => { 
     this.ngZone.run(() => {
     this.router.navigate(['dashboard']);
      });
      /*  this.ngZone.run(() => {
        window.location.reload() //temp routing workaround
        })*/
      });
    });
    }).catch((error) => {
      window.alert(error)
    })
  }


  /* Setting up user data when sign in with username/password, 
  sign up with username/password and sign in with social auth  
  provider in Firestore database using AngularFirestore + AngularFirestoreDocument service */
  SetUserData(user) {
    const userRef: AngularFirestoreDocument<any> = this.afs.doc(`users/${user.uid}`);
    const userData: User = {
      role: user.role,
      uid: user.uid,
      email: user.email,
      organization: user.organization ? user.organization : '', 
      displayName: user.displayName,
      photoURL: user.photoURL
    }
    return userRef.set(userData, {
      merge: true
    })
  }

 
  // Sign out 
  SignOut() {
    return this.afAuth.auth.signOut().then(() => {
      localStorage.removeItem('user');
      this.router.navigate(['sign-in']);
      this.userData = '';
      this.userMenu = false;
    })
  }

}