<div class="dashboard-panel">
  <div class="div-space"></div>    

  <mat-grid-list cols="8" rowHeight="100px">

    <mat-grid-tile [colspan]="(isHandset$ | async)? 8 :3 " [rowspan]="1">
      <mat-card class="dashboard-card dashboard-card-blue">
        <mat-card-header>
          <div class="dashboard-actions-month"> 
            <mat-label class="mat-label-header"><span>Select a date range</span></mat-label>
            <input matInput
                  placeholder="Date range"
                  [satDatepicker]="picker"
                  [value]="selectedMonth"  class="mat-select" [ngModel]="selectedMonth" (ngModelChange)="monthOnChange($event)">
            <div class="date-input">
              <sat-datepicker #picker [rangeMode]="true"></sat-datepicker>
              <sat-datepicker-toggle matSuffix [for]="picker"></sat-datepicker-toggle>
            </div>
          </div>
        </mat-card-header>
        <mat-card-content class="dashboard-card-content"> 
          <div class="dashboard-nav-icon">
            <mat-icon>calendar_today</mat-icon>
          </div>
        </mat-card-content>
      </mat-card>
    </mat-grid-tile>

    <mat-grid-tile [colspan]="(isHandset$ | async)? 8 :3 " [rowspan]="1">
      <mat-card class="dashboard-card dashboard-card-green">
        <mat-card-header>
          <h4>
            Total hours per project
          </h4>

          <div class="dashboard-actions"> 
          <mat-label>Select a project</mat-label>
          <mat-select class="dashboard-selected-project" placeholder="All Projects" (selectionChange)="dashboardFilterProject($event.value)">
            <mat-option *ngFor="let project of projects" [value]="project.name">
              {{ project.name }}
            </mat-option>
          </mat-select>
        </div>
        </mat-card-header>
        <mat-card-content class="dashboard-card-content">
          <div class="project"><mat-icon>business</mat-icon><span> Project:  {{projectName}}</span></div>
          <div class="dashboard-nav-icon">
            <mat-icon>business</mat-icon>
       </div>
        </mat-card-content>
      </mat-card>
    </mat-grid-tile>
  

    <!-- Payment history -->
      <mat-grid-tile  *ngIf="!(isHandset$ | async)" [colspan]="2" [rowspan]="9">
       <mat-card class="mat-timeline-card">
         <mat-card-header>
           <mat-card-title>Payment History</mat-card-title>
         </mat-card-header>
         <mat-divider></mat-divider>
         <mat-card-content id="timeline" class="mat-timeline-outer">
            <div class="row">
              <div class="col s12 m12 l12">
                <ul class="timeline" *ngIf="paymentsData && paymentsData.length">
                   <li class="event" *ngFor="let payment of paymentsData" [attr.data-date]="payment.date.toDate() | date:'M/dd/yyyy'">
                    <p class="payment-range">{{payment.range}}</p>
                    <p>Hours: {{payment.hours}}</p>
                    <p>Payment: ${{payment.amountPaid}}</p>
                   </li>
                </ul>
              </div>
            </div>
        </mat-card-content>
      </mat-card>
      </mat-grid-tile>
    
      <!-- hours table -->
      
      <mat-grid-tile [colspan]="(isHandset$ | async)? 8 :6 " [rowspan]="8">
     <mat-card class="mat-dashboard-card">
      <mat-form-field>
          <mat-label>Search ...</mat-label>
          <input matInput (keyup)="applyFilter($event)" placeholder="Ex. Support">
        </mat-form-field>
      <table mat-table [dataSource]="dataSource" matSortActive="date" matSortDirection="desc" matSort>
    <!-- Date Column -->
    <ng-container matColumnDef="date">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Date</th>
      <td mat-cell *matCellDef="let element"> {{element.date.toDate() | date:'M/dd/yyyy'}} </td>
      <td class="table-info-row" mat-footer-cell *matFooterCellDef>Total approved hours </td>
    </ng-container>
    
    <!-- Project Column -->
    <ng-container matColumnDef="project">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="dashboard-column">Project</th>
      <td mat-cell *matCellDef="let element" class="dashboard-column"> {{element.project}} </td>
      <td mat-footer-cell *matFooterCellDef> </td>
    </ng-container>

    <!-- Hours Column -->
    <ng-container matColumnDef="hours">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="dashboard-column">Hours</th>
      <td mat-cell *matCellDef="let element" class="dashboard-column"> {{element.hours}} </td>
      <td class="table-info-row" mat-footer-cell *matFooterCellDef>{{getTotalDashboardhours()}}</td>
    </ng-container>

    <!-- Type Column -->
    <ng-container matColumnDef="type">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="dashboard-column">Work Type</th>
      <td mat-cell *matCellDef="let element" class="dashboard-column"> {{element.type}} </td>
      <td mat-footer-cell *matFooterCellDef> </td>
    </ng-container>

    <!-- Description Column -->
    <ng-container matColumnDef="description">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="dashboard-column">Description</th>
      <td mat-cell *matCellDef="let element" class="dashboard-column"> {{element.description}} </td>
      <td mat-footer-cell *matFooterCellDef> </td>
    </ng-container>

    <!-- Comments Column -->
    <ng-container matColumnDef="comments">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="dashboard-column">Comment</th>
      <td mat-cell *matCellDef="let element" class="dashboard-column"> {{element.comments}} </td>
      <td class="table-info-row" mat-footer-cell *matFooterCellDef>Total unpaid hours</td>
    </ng-container>

    <!-- Payment Column -->
    <ng-container matColumnDef="paymentstatus">
     <th mat-header-cell *matHeaderCellDef mat-sort-header>Paid</th>
      <td mat-cell *matCellDef="let element" class="dashboard-disabledCheck" >
        <mat-checkbox [checked]="element.paymentstatus" [disabled]="true"></mat-checkbox>
       </td>
       <td class="table-info-row" mat-footer-cell *matFooterCellDef>{{getTotalUnpaidhours()}}</td>
    </ng-container>
    

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    <tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>
   </table>

   <mat-paginator [pageSizeOptions]="[12]" [hidePageSize]="true" showFirstLastButtons></mat-paginator>
      
    </mat-card>
  </mat-grid-tile>

  </mat-grid-list>


  </div> 
  

  