import { DatePipe } from '@angular/common';

export function getWeeks(months: number): any[] {
  const datePipe = new DatePipe('en-US');
  const weeks = [];

  // create two Date objects to act as lower and upper bound
  let rightNow = new Date();
  rightNow.setDate(rightNow.getDate() + 21); // add three weeks to the current date
  let twoMonthsAgo = new Date();
  twoMonthsAgo.setMonth(twoMonthsAgo.getMonth() - months);
  twoMonthsAgo = getMonday(twoMonthsAgo, 1);

  let current = new Date(twoMonthsAgo.getFullYear(), twoMonthsAgo.getMonth(), twoMonthsAgo.getDate() - 7);

  // a helper function to advance our iterator by one week
  const addOneWeekTo = (thisDate) => {
    thisDate.setDate(thisDate.getDate() + 7);
  };

  while (current <= rightNow) {
    const weekStart = new Date(current.getFullYear(), current.getMonth(), current.getDate() - current.getDay() + 1);
    const weekEnd = new Date(current.getFullYear(), current.getMonth(), current.getDate() - current.getDay() + 7);
    const weekId = datePipe.transform(weekStart, 'MMddyyyy') + datePipe.transform(weekEnd, 'MMddyyyy');

    let payrollPeriod = isCloseDate(current);

        if (payrollPeriod >= weekStart && payrollPeriod < weekEnd) {
          // split the week into two
          const firstWeekEnd = payrollPeriod;
          const secondWeekStart = new Date(firstWeekEnd.getFullYear(), firstWeekEnd.getMonth(), firstWeekEnd.getDate() + 1);
          const secondWeekEnd = weekEnd;
  
          const firstWeekId = datePipe.transform(weekStart, 'MMddyyyy') + datePipe.transform(firstWeekEnd, 'MMddyyyy');
          const secondWeekId = datePipe.transform(secondWeekStart, 'MMddyyyy') + datePipe.transform(secondWeekEnd, 'MMddyyyy');
  
          weeks.push(
            {
              start: weekStart,
              end: firstWeekEnd,
              date: datePipe.transform(weekStart, 'MM/dd/yyyy') + ' - ' + datePipe.transform(firstWeekEnd, 'MM/dd/yyyy'),
              id: firstWeekId
            },
            {
              start: secondWeekStart,
              end: secondWeekEnd,
              date: datePipe.transform(secondWeekStart, 'MM/dd/yyyy') + ' - ' + datePipe.transform(secondWeekEnd, 'MM/dd/yyyy'),
              id: secondWeekId
            }
          );
        }else{
          weeks.push({
            start: weekStart,
            end: weekEnd,
            date: datePipe.transform(weekStart, 'MM/dd/yyyy') + ' - ' + datePipe.transform(weekEnd, 'MM/dd/yyyy'),
            id: weekId
          });
        }


    addOneWeekTo(current);
  }

  return weeks;
}

function isCloseDate(date:Date) {
  for(let p of payrollEndDate){
    if (date.valueOf() >= p.start.valueOf() && date.valueOf() <= p.end.valueOf()) {
      //return end date if matches
      return p.end;
    }
  }
  return;
}

function getMonday(dateObject:any, firstDayOfWeekIndex:any) {
  const dayOfWeek = dateObject.getDay(),
      firstDayOfWeek = new Date(dateObject),
      diff = dayOfWeek >= firstDayOfWeekIndex ?
          dayOfWeek - firstDayOfWeekIndex :
          6 - dayOfWeek
  
  firstDayOfWeek.setDate(dateObject.getDate() - diff)
  firstDayOfWeek.setHours(0,0,0,0)
  
  return firstDayOfWeek
}

const payrollEndDate = [
  {start: new Date('02/09/2023'), end: new Date('02/21/2023')},
  {start: new Date('02/22/2023'), end: new Date('03/08/2023')},
  {start: new Date('03/09/2023'), end: new Date('03/24/2023')},
  {start: new Date('03/25/2023'), end: new Date('04/08/2023')},
  {start: new Date('04/09/2023'), end: new Date('04/23/2023')},
  {start: new Date('04/24/2023'), end: new Date('05/08/2023')},
  {start: new Date('05/09/2023'), end: new Date('05/24/2023')},
  {start: new Date('05/25/2023'), end: new Date('06/08/2023')},
  {start: new Date('06/09/2023'), end: new Date('06/23/2023')},
  {start: new Date('06/24/2023'), end: new Date('07/08/2023')},
  {start: new Date('07/09/2023'), end: new Date('07/24/2023')},
  {start: new Date('07/25/2023'), end: new Date('08/08/2023')},
  {start: new Date('08/09/2023'), end: new Date('08/24/2023')},
  {start: new Date('08/25/2023'), end: new Date('09/08/2023')},
  {start: new Date('09/09/2023'), end: new Date('09/23/2023')},
  {start: new Date('09/24/2023'), end: new Date('10/08/2023')},
  {start: new Date('10/09/2023'), end: new Date('10/24/2023')},
  {start: new Date('10/25/2023'), end: new Date('11/08/2023')},
  {start: new Date('11/09/2023'), end: new Date('11/30/2023')},
  {start: new Date('12/01/2023'), end: new Date('12/31/2023')},
  {start: new Date('01/01/2024'), end: new Date('01/31/2024')},
  {start: new Date('02/01/2024'), end: new Date('02/29/2024')},
  {start: new Date('03/01/2024'), end: new Date('03/31/2024')},
  {start: new Date('04/01/2024'), end: new Date('04/30/2024')},
  {start: new Date('05/01/2024'), end: new Date('05/31/2024')},
  {start: new Date('06/01/2024'), end: new Date('06/30/2024')},
  {start: new Date('07/01/2024'), end: new Date('07/31/2024')},
  {start: new Date('08/01/2024'), end: new Date('08/31/2024')},
  {start: new Date('09/01/2024'), end: new Date('09/30/2024')},
  {start: new Date('10/01/2024'), end: new Date('10/31/2024')},
  {start: new Date('11/01/2024'), end: new Date('11/30/2024')},
  {start: new Date('12/01/2024'), end: new Date('12/31/2024')},
  {start: new Date('01/01/2025'), end: new Date('01/31/2025')},
]