import { Component, OnInit, Inject, TemplateRef, ViewChild, Optional  } from '@angular/core';
import { AuthService } from '../../shared/services/auth/auth.service';
import { AngularFireAuth } from "@angular/fire/auth";
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { Router } from "@angular/router";
import {animate, state, style, transition, trigger} from '@angular/animations';
import { User } from "../../shared/interfaces/user"
import {MatDialog, MatDialogRef, MatDialogConfig, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { firestore } from 'firebase/app';
import { MatSnackBar } from "@angular/material/snack-bar";
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { first, last, map, shareReplay, take } from 'rxjs/operators';
import { getMonths } from '../../shared/common/months';
import {MatTable, MatTableDataSource} from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';
import { getWeeks } from '../../shared/common/weeks';
import { MatSort } from '@angular/material/sort';
import {emailService} from '../../shared/services/email/email';
import { TableUtil } from "./tableUtil";
import { toCommonDate} from '../../shared/common/to-common-date';


export interface Transaction {
  user: string;
  project: string;
  hours: number;
  dateID: string;
}

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})

export class AdminComponent implements OnInit {
  orgArray:any
  totaltopay:any;
  ratetopay:any;
  unpaid:any;
  amountPaid:any;
  SelectedDate:any;
  SelectedDateProjects:any;
  SelectedDateSheets:any;
  SelectedUser:any;
  SelectedUserSheet:any;
  adminListUsers: any;
  perHourUsers: any;
  sheetUsers: any;
  statuses = ['All', 'Open', 'Approved', 'Rejected']
  columnsToDisplay = ['id', 'name', 'status'];
  usersToDisplay = ['email'];
  expandedElement: any;
  users: any;
  name: string;
  selectedUser: any;
  listedUser: any;
  createdProject: any;
  createdOrg: any;
  user = JSON.parse(localStorage.getItem('user'));  
  weeksDashboard:any;
  projects:any;
  organizations:any;
  selectedOrg:any;
  selectedProject: any;
  projectHours: any;
  projectName: any;
  dashboardMonths:any;
  selectedMonth: any;
  monthName:any;
  monthHours:any;
  private authService: AuthService;
  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
  .pipe(
    map(result => result.matches),
    shareReplay()
  );
  
  private getWeeks(months: number): any {
    return  getWeeks(months);
  }
  sheetdisplayedColumns: string[] = ['user', 'date', 'name', 'hours', 'status', 'actions'];
  sheetSource = new MatTableDataSource([]);
  filteredSheets = { user:'', createdOn: '', name: '', hours:'',status:'', topFilter: false};
  sheetSourcePending = new MatTableDataSource([]);
 
  detailDataSource = new MatTableDataSource([]); 
  
  paymentsDataSource = new MatTableDataSource([]); 
  paymentsdisplayedColumns: string[] = ['date', 'hours', 'amountPaid', 'range', 'id'];
  adminUsers = new MatTableDataSource([]);

  projectsSource  = new MatTableDataSource([]);
  DetaildisplayedColumns: string[] = ['createdBy', 'date', 'project', 'hours', 'workType', 'description', 'comments', 'paymentstatus'];
  TransactionsdisplayedColumns: string[] = ['user', 'project', 'hours'];
  transactions = new MatTableDataSource([]);
  filteredValues = { user:'', project:'', dateID:'', topFilter: false};
  filteredHours  = {createdBy:'', createdOn:'', date:'', dateID:'', description:'', hours:'', id:'', isEditable:'', project:'', type:'', updatedOn:'', weekID:'', user:'' , topFilter: false};


  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  // create a class for the sheets table
  @ViewChild('sheetPaginator', {static: true}) sheetPaginator: MatPaginator;
  @ViewChild('sheetSort', {static: true}) sheetSort: MatSort;
  @ViewChild('sheetPendingPaginator', {static: true}) sheetPendingPaginator: MatPaginator;
  @ViewChild('sheetPendingSort', {static: true}) sheetPendingSort: MatSort;

  
  @ViewChild('detailPaginator', {static: true}) detailPaginator: MatPaginator;
  @ViewChild('detailSort', {static: true}) detailSort: MatSort;

  //payments paginator
  
  
  @ViewChild('paymentsaginator', {static: true}) paymentsPaginator: MatPaginator;
  @ViewChild('paymentsSort', {static: true}) paymentsSort: MatSort;

  //Users paginator
  
  @ViewChild('usersPaginator', {static: true}) usersPaginator: MatPaginator;

  

  @ViewChild(MatTable) table: MatTable<any>;
  
  //projects paginator
  @ViewChild('projectsPaginator', {static: true}) projectsPaginator: MatPaginator;
  @ViewChild('transactionPaginator', {static: true}) transactionPaginator: MatPaginator;

  @ViewChild('adduserDialog') adduserDialog: TemplateRef<any>;
  public adduserDialogRef: MatDialogRef<TemplateRef<any>>;
  
  @ViewChild('addProjectDialog') addProjectDialog: TemplateRef<any>;
  public addProjectDialogRef: MatDialogRef<TemplateRef<any>>;

  
  
  @ViewChild('addOrgDialog') addOrgDialog: TemplateRef<any>;
  public addOrgDialogRef: MatDialogRef<TemplateRef<any>>;
  
  
  @ViewChild('changeOrgDialog') changeOrgDialog: TemplateRef<any>;
  public changeOrgDialogRef: MatDialogRef<TemplateRef<any>>;

  
  @ViewChild('addOrgtoProjectDialog') addOrgtoProjectDialog: TemplateRef<any>;
  public addOrgtoProjectDialogRef: MatDialogRef<TemplateRef<any>>;

  
  @ViewChild('removeOrgDialog') removeOrgDialog: TemplateRef<any>;
  public removeOrgDialogRef: MatDialogRef<TemplateRef<any>>;
  
  @ViewChild('paidHoursDialog') paidHoursDialog: TemplateRef<any>;
  public paidHoursDialogRef: MatDialogRef<TemplateRef<any>>;

  constructor(
    public afs: AngularFirestore,   // Inject Firestore service
    public afAuth: AngularFireAuth, // Inject Firebase auth service
    public router: Router,  
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    private breakpointObserver: BreakpointObserver,
    private email :emailService,

  ) {   
    this.breakpointObserver.observe(['(max-width: 600px)']).subscribe(result => {
    this.DetaildisplayedColumns = result.matches ? 
            ['date', 'project', 'hours'] : 
            ['createdBy', 'date', 'project', 'hours', 'type', 'description', 'comments', 'paymentstatus'];
    });
    
    
    this.GetUsers(); 
    this.getOrganizations() 
    this.GetListofUsers(); 
    this.listedUser = "All Users";   
    this.getProjects();
    this.dashboardMonths = getMonths(12);
    this.getProjectsList();
    this.transactions.filterPredicate = this.customFilterPredicate();
    this.detailDataSource.filterPredicate = this.customFilterPredicateDetails();
    // this.GetUserTimeSheets();
    this.weeksDashboard =  getWeeks(3);
    this.weeksDashboard.unshift({date:'All Weeks', id:'allweeks'});
    this.TimeSheetsStatus();
  }
  exportTable() {
    const newArray = [];
    for(let obj of this.transactions.filteredData){
      let newObj = {
        "User": obj.user,
        "Project": obj.project,
        "Hours": obj.hours
      }
      newArray.push(newObj)
    }
    TableUtil.exportArrayToExcel(newArray);
  }
 
 
  exportHours() {
    const newArray = [];
    for(let obj of this.detailDataSource.filteredData){
      let newObj = {
        "Date": toCommonDate(obj.date.toDate()),
        "User": obj.createdBy.name,
        "Project": obj.project,
        "Hours": obj.hours,
        "Work Type": obj.type,
        "Description": obj.description,
        "Comments": obj.comments
      }
      newArray.push(newObj)
    }
    TableUtil.exportArrayToExcel(newArray);
  }


  TimeSheetsStatus() {
    //clear the existing data 
    this.afs.collection('users').valueChanges().subscribe(res => {
      let sheetData = [];
      let sheetPending = [];
      let user = Object.keys(res).map(i => res[i]);
       user.forEach(user => {  
         //All sheets  
        this.afs.collection('timesheets').doc(user.email).collection('sheets', ref => ref.where('status', '!=', 'pending')).valueChanges().subscribe(sheets=> {
          let response = Object.keys(sheets).map(i => sheets[i]);
          response.forEach(sheet => { 
            sheet.user = user.displayName;
            sheet.emailID = user.email;
            sheetData.push(sheet);
          });
          this.sheetSource.data = sheetData;
        });
        

        //pending sheets only
        
        this.afs.collection('timesheets').doc(user.email).collection('sheets', ref => ref.where('status', '==', 'pending').orderBy('id', 'asc')).valueChanges().subscribe(sheets=> {
          let response = Object.keys(sheets).map(i => sheets[i]);
          response.forEach(sheet => { 
          sheet.user = user.displayName;
          sheet.emailID = user.email;
          sheetPending.push(sheet);
        });
        this.sheetSourcePending.data = sheetPending;
        console.log(this.sheetSourcePending.data)
      });
      });
    });
    
  }

  applyFilterStatus(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.sheetSource.filter = filterValue.trim().toLowerCase();
  }

  applyFilterPending(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.sheetSourcePending.filter = filterValue.trim().toLowerCase();
  }

  applyFilterHourDetail(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;

    this.filteredHours.project = filterValue.trim().toLowerCase();
    this.detailDataSource.filter = JSON.stringify(this.filteredHours)
  }

  viewSheet(index: number) {
    const url = this.router.serializeUrl(
      this.router.createUrlTree([`admin/viewsheet/${index['emailID']}/${index['id']}`])
    );
  
    window.open(url, '_blank');

     // console.log(index);
    // this.router.navigate([]);
  }

    /** Gets the total hours of all transactions. */
    getTotalhours() {
     return this.transactions.filteredData.map(t => t.hours).reduce((acc, value) => acc + value, 0);
    }

    monthOnChangeb(m){
      this.monthName = m.full;
      let startDate = new Date(m.year+'-'+m.name+'-1');
      let lastDay = new Date(m.year,startDate.getMonth()+1,0).getDate();
      let endDate = new Date(m.year+'-'+m.name+'-'+lastDay);
      endDate.setHours(4, 0, 0);
      endDate = new Date(endDate.toLocaleString("en-US", {timeZone: "America/Los_Angeles"}))
      let param = ref => ref.where('date', '>=',  startDate).where('date', '<=',  endDate);  
  
      if (m.name == 'all'){
        this.GetUserTimeSheets(); 
       }else{ 
        //this.weeksDashboard =  getWeeks(3);
        //this.weeksDashboard.unshift({date:'All Weeks', id:'allweeks'});
        this.GetUserSpecificTimeSheets(param, 'month'); 
       }
      }
    
    monthOnChange(d){
      this.SelectedDateProjects = d;
      const newDate = {begin :d.begin, end:d.end};
      let newBDate = (newDate.begin).toLocaleDateString('en-US');
      newBDate = new Date(`${newBDate} 00:00:00 PST`)
      newDate.begin =newBDate;
      let newEDate = (newDate.end).toLocaleDateString('en-US');
      newEDate = new Date(`${newEDate} 23:59:00 PST`)
      newDate.end = newEDate;
      let param = ref => ref.where('date', '>=',  newDate.begin).where('date', '<=', newDate.end);  
      this.GetUserSpecificTimeSheets(param, 'month'); 
    }
    
    sheetDetailOnChange(d){
      this.SelectedDateSheets = d;
      const newDate = {begin :d.begin, end:d.end};
      let newBDate = (newDate.begin).toLocaleDateString('en-US');
      newBDate = new Date(`${newBDate} 00:00:00 PST`)
      newDate.begin =newBDate;
      let newEDate = (newDate.end).toLocaleDateString('en-US');
      newEDate = new Date(`${newEDate} 23:59:00 PST`)
      newDate.end = newEDate;
     // console.log('search', newDate)
      if(this.SelectedUserSheet){
       this.GetUserSheets(this.SelectedUserSheet, newDate);
      }else{
        this.sheetSource.data = [];
      }
    }

    sheetOnChange(user){
      if(user.uid == "all-users"){
        if(this.SelectedDateSheets){
          let d =this.SelectedDateSheets;
          const newDate = {begin :d.begin, end:d.end};
          let newBDate = (newDate.begin).toLocaleDateString('en-US');
          newBDate = new Date(`${newBDate} 00:00:00 PST`)
          newDate.begin =newBDate;
          let newEDate = (newDate.end).toLocaleDateString('en-US');
          newEDate = new Date(`${newEDate} 23:59:00 PST`)
          newDate.end = newEDate;
          this.GetUsersallSheets(newDate);
        }
        else{
          this.GetUserSheetsAll();
        }
        this.SelectedUserSheet = user;
      }else{
        if(this.SelectedDateSheets){
          let d =this.SelectedDateSheets;
          const newDate = {begin :d.begin, end:d.end};
          let newBDate = (newDate.begin).toLocaleDateString('en-US');
          newBDate = new Date(`${newBDate} 00:00:00 PST`)
          newDate.begin =newBDate;
          let newEDate = (newDate.end).toLocaleDateString('en-US');
          newEDate = new Date(`${newEDate} 23:59:00 PST`)
          newDate.end = newEDate;
          this.GetUserSheets(user, newDate);
        }
        else{
          this.GetUserAllSheets(user);
        }
        this.SelectedUserSheet = user;
      }
    }


    applyFilter(event: Event) {
      const filterValue = (event.target as HTMLInputElement).value;
      let filter = {
        user: filterValue.trim().toLowerCase(),
        project: filterValue.trim().toLowerCase(),
        dateID: filterValue.trim().toLowerCase(),
        topFilter: true
      }
      this.transactions.filter = JSON.stringify(filter)
      if (this.transactions.paginator) {
        this.transactions.paginator.firstPage();
      }
    }

    applyFilterUser(filterValue: string) {
      this.filteredValues.topFilter = false;
      
      if(filterValue == 'All Users'){
        this.filteredValues.user = '';
      }
      else{
        this.filteredValues.user = filterValue.trim().toLowerCase();
      }
      this.transactions.filter = JSON.stringify(this.filteredValues);
      if (this.transactions.paginator) {
        this.transactions.paginator.firstPage();
      }
    }

    applyFilterProject(filterValue: string) {
      this.filteredValues.topFilter = false;

      if(filterValue == 'All Projects'){
        this.filteredValues.project = '';
      }
      else{
        this.filteredValues.project = filterValue.trim().toLowerCase();
      }

      this.transactions.filter = JSON.stringify(this.filteredValues);
  
      if (this.transactions.paginator) {
        this.transactions.paginator.firstPage();
      }
    }

    

    filterSheets(filterValue: string) {
      if(filterValue == 'All'){
        this.sheetSource.filter = '';
      }
      else{
        this.sheetSource.filter = filterValue.trim().toLowerCase();
      } 
  
      if (this.sheetSource.paginator) {
        this.sheetSource.paginator.firstPage();
      }
    }

    

    filterProjects(filterValue: string) {
      this.filteredHours.topFilter = false;

      if(filterValue == 'All Projects'){
        this.filteredHours.project = '';
      }
      else{
        this.filteredHours.project = filterValue.trim().toLowerCase();
      }

      this.detailDataSource.filter = JSON.stringify(this.filteredHours);
  
      if (this.detailDataSource.paginator) {
        this.detailDataSource.paginator.firstPage();
      }
    }
  
    customFilterPredicate() {
      const myFilterPredicate = function(data:Transaction,        filter:string) :boolean {
        let searchString = JSON.parse(filter);
        let userFound    = data.user.toString().trim().toLowerCase().indexOf(searchString.user.toLowerCase()) !== -1;
        let dateIDFound  = data.dateID.toString().trim().toLowerCase().indexOf(searchString.dateID.toLowerCase()) !== -1;
        let projectFound = data.project.toString().trim().toLowerCase().indexOf(searchString.project.toLowerCase()) !== -1
        if (searchString.topFilter) {
            return userFound || projectFound  || dateIDFound
        } else {
            return userFound && projectFound && dateIDFound 
        }
      }
      return myFilterPredicate;
    }

    customFilterPredicateDetails() {
      const myFilterPredicate = function(data:Transaction,        filter:string) :boolean {
        let searchString = JSON.parse(filter);
        let dateIDFound  = data.dateID.toString().trim().toLowerCase().indexOf(searchString.dateID.toLowerCase()) !== -1;
        let projectFound = data.project.toString().trim().toLowerCase().indexOf(searchString.project.toLowerCase()) !== -1
        if (searchString.topFilter) {
            return projectFound  || dateIDFound
        } else {
            return projectFound && dateIDFound 
        }
      }
      return myFilterPredicate;
    }

    GetUserTimeSheets() {
      this.afs.collection('users').valueChanges().subscribe((res) => {
      let data = [];
      let user = Object.keys(res).map(i => res[i]);
      user.forEach(user => {    
      this.afs.collection('timesheets').doc(user.email).collection('sheets', ref => ref.where('status', '==', 'approved')).valueChanges().subscribe((response) => {
       const res = Object.keys(response).map(i => response[i]);
       res.forEach(element => {    
        this.afs.collection('timesheets').doc(user.email).collection(element.id).valueChanges().subscribe((response) => {
          const res = Object.keys(response).map(i => response[i]);
          res.forEach(element => { 
          element['user'] = element.createdBy.name;
          data.push(element);  
        });     
        data = this.mergeValues(data,["user", "project"], ["hours"])
        this.transactions.data = data;
       //console.log(this.transactions.data)
        });
         })  
      });
     });
    })
   }
   
   
   GetUserSpecificTimeSheets(param, mode) {
   this.afs.collection('users').valueChanges().subscribe((res) => {
    let data = []; 
    let user = Object.keys(res).map(i => res[i]);
    user.forEach(user => {    
    this.afs.collection('timesheets').doc(user.email).collection('sheets', ref => ref.where('status', '==', 'approved')).valueChanges().subscribe((response) => {
     const res = Object.keys(response).map(i => response[i]);
     res.forEach(element => {    
      this.afs.collection('timesheets').doc(user.email).collection(element.id, param).valueChanges().subscribe((response) => {
        const res = Object.keys(response).map(i => response[i]);
        res.forEach(element => { 
        element['user'] = element.createdBy.name;
        data.push(element);  
      });     
      data = this.mergeValues(data,["user", "project"], ["hours"])
     this.transactions.data = data;
      });
       })  
    });
   });
  })
 }

 mergeValues( arr, match, sum ) { 
 // define vars
 var newArray, key, processRecord

 // define function
 // function to process each record
 processRecord = function( item ) {
     // define vars
     var key, getKey, sumFields, record;
     // define functions
     getKey = function ( field ) { key += item[field]; } // Creates a key 
     sumFields = function ( field ) { record[field] += item[field];} // sums fields

     // code
     key = "";  // create a blank key
     match.forEach( getKey );  // create a unique key
     if(newArray.has( key ) ){  // does it exist
         record = newArray.get( key );  // it does so get the record
         sum.forEach( sumFields );  // sum the fields
     }else{
         newArray.set( key, item ); // the key does not exist so add new record
     }
 }

 // code
 newArray = new Map();  // create a new map
 arr.forEach( processRecord );  // process each record
 return ( [...newArray.values()] ); // convert map to array and return it
}


  getProjectsList(){
    this.afs.collection('admin').doc("settings").collection('projects').valueChanges().subscribe((response) => {
    response.unshift({name:'All Projects'})
    this.projects = response;
     });
  }

  GetUsers() {
    let allUsers = {
      displayName:"All Users", 
      photoURL: 'https://firebasestorage.googleapis.com/v0/b/timesheet-f27f3.appspot.com/o/app%2Fhs.png?alt=media&token=8250ac62-fd7c-46a4-988d-210a470c2bec',
      uid: "all-users"
    }
     this.afs.collection('users').valueChanges().subscribe((response) => {
     this.adminUsers.data = response;
     this.perHourUsers = response;
     this.sheetUsers = response;
     this.perHourUsers.unshift(allUsers)
     this.sheetUsers.unshift(allUsers)
    });
  }

  GetListofUsers() {
    this.afs.collection('users').valueChanges().subscribe((response) => {
    this.adminListUsers = response;
    this.adminListUsers.unshift({displayName:'All Users'});
   });
 }
  
  getProjects(){
    this.afs.collection('admin').doc("settings").collection('projects', ref => 
    ref.orderBy('createdOn','desc')).valueChanges().pipe(first()).subscribe((response) => {
    this.projectsSource.data =  response;
    response.forEach(project => {
      //list users associated to projects
      if (project.team){
        project.team.forEach(member => {
          project.team = [];  
          this.afs.collection('users', ref => ref.where('email', '==', member)).valueChanges().subscribe((user) => {
            project.team.push(user[0]);
          })
        })
      }
    //list organizations associated to projects
    if (project.orgs){
      project.orgs.forEach(orgID => {
        project.orgs = [];  
        this.afs.collection('admin').doc('settings').collection('organizations', ref => ref.where('id', '==', orgID)).valueChanges().pipe(take(1)).subscribe((org) => {
          project.orgs.push(org[0]);
        })
      })
    }
    })
  });
  }
/*
  addUsertoProject(element, index): void {

    this.afs.collection('users').valueChanges().subscribe((user) => {
     this.dialog.open(addUsertoProjectDialog, {
       width: '400px',
       data: {project: this.expandedElement, users: user}
      });

   });
   
   this.expandedElement = this.expandedElement == element ? element : null;
  }   */
  addProject(): void{
    
    const dialogConfig = new MatDialogConfig();
    dialogConfig.restoreFocus = false;
    dialogConfig.autoFocus = false;
    dialogConfig.role = 'dialog';
    dialogConfig.width= '400px';
    dialogConfig.data = {project: 'test', users: this.user};
     
    this.addProjectDialogRef = this.dialog.open(this.addProjectDialog,  dialogConfig);

  }
 addProjectSave(project){
   
  let data = {
    id: project.replace(/\s+/g, '-').toLowerCase(),
    name: project,
    createdOn: new Date(),
    createdBy: this.user.email,
    status: 'active',
    team: [],
    orgs: []
  }

  this.afs.collection('admin').doc("settings").collection('projects').doc(data.id).set(data).then(() => {
    let d = this.projectsSource.data;
    d.unshift(data);
    this.projectsSource.data = d;
   this.openSnackBar(data.name+ " was created", "X Close");
   this.closeAddProjectDialog();
}).catch(function(error) {   
  this.closeAddProjectDialog();
  this.openSnackBar(error, "X Close");

})
 }

 closeAddProjectDialog(): void {
  this.addProjectDialogRef.close();
  this.createdProject = '';
}

  addUsertoProject(index): void {
    this.afs.collection('users').valueChanges().subscribe((user) => {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.restoreFocus = false;
      dialogConfig.autoFocus = false;
      dialogConfig.role = 'dialog';
      dialogConfig.data = {project: this.expandedElement, users: user, index:index};
  
      this.adduserDialogRef = this.dialog.open(this.adduserDialog,  dialogConfig);
   });
  }


  closeAddUserDialog(): void {
    this.adduserDialogRef.close();
    this.selectedUser = '';
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
       duration: 3000,
       horizontalPosition: 'right',
    });
 } 
 
  addUserSave(u, e, index){
   this.afs.collection('admin').doc("settings").collection('projects').doc(e.id).update( {
      team: firestore.FieldValue.arrayUnion( u.email)
    }).then(() => {
     this.openSnackBar(u.displayName+ " was added to " +e.name, "X Close");
     e.team.push(u)
 //    this.expandedElement = this.expandedElement.id === this.projectsSource.data[index].id ? this.projectsSource.data[index] : this.expandedElement;
     this.closeAddUserDialog();
  }).catch(function(error) {
    
    this.closeAddUserDialog();
    this.openSnackBar(error, "X Close");
  
  })
}

userOnChange(user){
  if(user.uid == "all-users"){
    if(this.SelectedDate){
      let d =this.SelectedDate;
      const newDate = {begin :d.begin, end:d.end};
      let newBDate = (newDate.begin).toLocaleDateString('en-US');
      newBDate = new Date(`${newBDate} 00:00:00 PST`)
      newDate.begin =newBDate;
      let newEDate = (newDate.end).toLocaleDateString('en-US');
      newEDate = new Date(`${newEDate} 23:59:00 PST`)
      newDate.end = newEDate;
      this.GetallSheetsDates(newDate);
    }
    else{
      this.GetUsersTime();
    }
    this.SelectedUser = user;
  }else{
    if(this.SelectedDate){
      let d =this.SelectedDate;
      const newDate = {begin :d.begin, end:d.end};
      let newBDate = (newDate.begin).toLocaleDateString('en-US');
      newBDate = new Date(`${newBDate} 00:00:00 PST`)
      newDate.begin =newBDate;
      let newEDate = (newDate.end).toLocaleDateString('en-US');
      newEDate = new Date(`${newEDate} 23:59:00 PST`)
      newDate.end = newEDate;
      this.GetUserTimeDates(user, newDate);
    }
    else{
      this.GetUserTime(user);
    }
    this.SelectedUser = user;
  }
}

  dateDetailOnChange(d){
    this.SelectedDate = d;
    const newDate = {begin :d.begin, end:d.end};
    let newBDate = (newDate.begin).toLocaleDateString('en-US');
    newBDate = new Date(`${newBDate} 00:00:00 PST`)
    newDate.begin =newBDate;
    let newEDate = (newDate.end).toLocaleDateString('en-US');
    newEDate = new Date(`${newEDate} 23:59:00 PST`)
    newDate.end = newEDate;
   // console.log('search', newDate)
    if(this.SelectedUser){
     this.GetUserTimeDates(this.SelectedUser, newDate);
    }else{
      this.detailDataSource.data = [];
    }
 }
    

GetUserTime(user) {
  let data = [];
  this.afs.collection('timesheets').doc(user.email).collection('sheets', ref=> ref.where('status', '==', 'approved')).valueChanges().subscribe((response) => {
    if(response.length >0){
      const res = Object.keys(response).map(i => response[i]);
    res.forEach(element => {    
    this.afs.collection('timesheets').doc(user.email).collection(element.id, ref => ref.orderBy('date', 'asc')).valueChanges().subscribe((response) => {
      const res = Object.keys(response).map(i => response[i]);
      res.forEach(element => { 
      data.push(element);  
    });    
   // console.log(data) 
    this.detailDataSource.data = data;       
    });
     })  
    }else{
      this.detailDataSource.data = [];
    }
 });
 
}
GetUsersTime() {
  let data = [];
  this.afs.collection('users').valueChanges().subscribe((res)=> {
    if(res.length >0){
      const r = Object.keys(res).map(i => res[i]);
      res.forEach((e:any) => {
        this.afs.collection('timesheets').doc(e.email).collection('sheets', ref=> ref.where('status', '==', 'approved')).valueChanges().subscribe((response) => {
        if(response.length >0){
          const res = Object.keys(response).map(i => response[i]);
          res.forEach(element => {
            this.afs.collection('timesheets').doc(e.email).collection(element.id, ref => ref.orderBy('date', 'asc')).valueChanges().subscribe((response) => {
              const res = Object.keys(response).map(i => response[i]);
              res.forEach(element => {
                data.push(element);
              })
              this.detailDataSource.data = data;
            });
          })
        }else{
          this.detailDataSource.data = [];
        }
      })
    })
    }else{
      this.detailDataSource.data = [];
    }
  })
}

GetUserTimeDates(user, param) {
  console.log('Current timestamp', param)
  let data = [];
  this.afs.collection('timesheets').doc(user.email).collection('sheets', ref=> ref.where('status', '==', 'approved')).valueChanges().subscribe((response) => {
   if(response.length >0){
   const res = Object.keys(response).map(i => response[i]);
   res.forEach(element => {    
    this.afs.collection('timesheets').doc(user.email).collection(element.id, ref => ref.where('date', '>=',  param.begin).where('date', '<=',  param.end).orderBy('date', 'asc')).valueChanges().subscribe((response) => {
      const res = Object.keys(response).map(i => response[i]);
      res.forEach(element => { 
      data.push(element);  
    });    
    this.detailDataSource.data = data;       
    });
     })  
    }else{
      this.detailDataSource.data = [];
    }
 });
 
}

GetUserAllSheets(user) {
  this.afs.collection('timesheets').doc(user.email).collection('sheets', ref=> ref.where('status', '==', 'approved')).valueChanges().subscribe((response) => {
    if(response.length >0){
      this.sheetSource.data = response; 
    }else{
      this.sheetSource.data = [];
    }
 });
 
}

GetUserSheets(user, param) {
  this.afs.collection('timesheets').doc(user.email).collection('sheets', ref=> ref.where('status', '!=', 'pending').where('date', '>=',  param.begin).where('date', '<=',  param.end).orderBy('date', 'asc')).valueChanges().subscribe((response) => {
    this.sheetSource.data = response;
 });
 
}


GetUserSheetsAll() {
  this.afs.collection('users').valueChanges().subscribe((res)=> {
    if(res.length >0){
      const r = Object.keys(res).map(i => res[i]);
      res.forEach((e:any) => {
        this.afs.collection('timesheets').doc(e.email).collection('sheets', ref=> ref.where('status', '!=', 'pending')).valueChanges().subscribe((response) => {
        if(response.length >0){
          this.sheetSource.data = response;
        }else{
          this.sheetSource.data= [];
        }
      })
    })
    }else{
      this.sheetSource.data = [];
    }
  })
}

GetUsersallSheets(param) {
  this.afs.collection('users').valueChanges().subscribe((res)=> {
    if(res.length >0){
      const r = Object.keys(res).map(i => res[i]);
      res.forEach((u:any) => {
        this.afs.collection('timesheets').doc(u.email).collection('sheets', ref=> ref.where('status', '!=', 'pending').where('date', '>=',  param.begin).where('date', '<=',  param.end).orderBy('date', 'asc')).valueChanges().subscribe((response) => {
        if(response.length >0){
          this.sheetSource.data = response;
        }else{
          this.sheetSource.data = [];
        }
      })
    })
    }else{
      this.sheetSource.data = [];
    }
  })
}

GetallSheetsDates(param) {
  let data = [];
  this.afs.collection('users').valueChanges().subscribe((res)=> {
    if(res.length >0){
      const r = Object.keys(res).map(i => res[i]);
      res.forEach((e:any) => {
        this.afs.collection('timesheets').doc(e.email).collection('sheets', ref=> ref.where('status', '==', 'approved')).valueChanges().subscribe((response) => {
        if(response.length >0){
          const res = Object.keys(response).map(i => response[i]);
          res.forEach(element => {
            this.afs.collection('timesheets').doc(e.email).collection(element.id, ref => ref.where('date', '>=',  param.begin).where('date', '<=',  param.end).orderBy('date', 'asc')).valueChanges().subscribe((response) => {
              const res = Object.keys(response).map(i => response[i]);
              res.forEach(element => {
                data.push(element);
              })
              this.detailDataSource.data = data;
            });
          })
        }else{
          this.detailDataSource.data = [];
        }
      })
    })
    }else{
      this.detailDataSource.data = [];
    }
  })
}


    /** Gets the total hours of all transactions. */
    getTotalDashboardhours() {
      return this.detailDataSource.filteredData.map(t => t.hours).reduce((acc, value) => acc + value, 0);
     }

         /** Gets the total unpaid hours for the selected period. */
    getTotalUnpaidhours() {
     return this.detailDataSource.filteredData.filter(h => !h.paymentstatus).map(t => t.hours).reduce((acc, value) => acc + value, 0);
     }

     paidHours(dateRange){
      this.unpaid = this.getTotalUnpaidhours();
      const dialogConfig = new MatDialogConfig();
      dialogConfig.restoreFocus = false;
      dialogConfig.autoFocus = false;
      dialogConfig.role = 'dialog';
      dialogConfig.width= '400px';
      dialogConfig.data = {hours: this.unpaid, amount:this.amountPaid, range:dateRange};

      this.paidHoursDialogRef = this.dialog.open(this.paidHoursDialog,  dialogConfig);

    }

    paidHoursSave(amount, range){
      let dateRange;
      if (range){
       dateRange = range.begin.toLocaleDateString()+' - '+range.end.toLocaleDateString();
      }else{
       dateRange = new Date().toLocaleDateString();
      }
      let filteredData = this.detailDataSource.filteredData.filter(h => !h.paymentstatus);
      let paymentID = this.afs.createId();
  
       let payment  = {
        hours: this.getTotalUnpaidhours(),
        paymentID : paymentID,
        amountPaid: amount,
        range : dateRange,
        date: new Date(),
       }
       // lets create a new payments table
       this.afs.collection('timesheets').doc(filteredData[0].createdBy.email).collection("payments").doc(paymentID).set(payment); 
      
      //update the sheets
      try{
      filteredData.forEach(sheet => {
       this.afs.collection('timesheets').doc(sheet.createdBy.email).collection(sheet.dateID).doc(sheet.id).update( {
         paymentstatus: <boolean> true, paymentID: paymentID
        })
       });
       this.userOnChange(filteredData[0].createdBy);
       let subject = 'Time payment confirmation - '+dateRange;
       let to =  filteredData[0].createdBy.email;
       let name = filteredData[0].createdBy.name;
       let message =  '<p>Hi '+name+'!</p>'+
       '<p>&nbsp;</p>'+
       '<p>The HiView management team have approved <strong>'+payment.hours+'</strong> hours for your next payment.</p>'+
       '<p>Total amount paid: <strong>$'+payment.amountPaid+'</strong></p>'+
       '<p>&nbsp;</p>'+
       '<p>If you have any questions, please email accounting@hiviewsolutions.com'; //+
      //   '<p><a href="'+origin+'/view/'+data.sheet+'">View TimeSheet</a></p>';
       this.email.emailNotification(to, subject, message).subscribe(data=> {
      //   console.log(data);
       });
       this.paidHoursClose();
       this.openSnackBar("They payment information has been updated ", "X Close");
      } catch(err) {
        this.openSnackBar("Error: "+ err, "X Close");
        this.paidHoursClose()
      } 
     }
  
   paidHoursClose(): void {
    this.paidHoursDialogRef.close();
    this.ratetopay = '';
    this.amountPaid = '';
    this.totaltopay = '';
  }

    
  paidHoursCal(rate) {
   this.amountPaid = rate * this.unpaid;
   this.totaltopay = this.amountPaid;
  }

  //Payment history

  userPayment(user){   
    this.afs.collection('timesheets').doc(user.email).collection("payments").valueChanges().subscribe(payments => {
    this.paymentsDataSource.data = payments;
  //console.log(payments)
    });    
  }

    //Organization Units

    getOrganizations(){   
      this.afs.collection('admin').doc('settings').collection('organizations').valueChanges().subscribe(org => {
      this.organizations = org;
      this.organizations.unshift({displayName:'All Users', id:'all-system-users', selected:true})
      // console.log(this.organizations)
      });    
    }

    
    addOrg(): void{
    
        const dialogConfig = new MatDialogConfig();
        dialogConfig.restoreFocus = false;
        dialogConfig.autoFocus = false;
        dialogConfig.role = 'dialog';
        dialogConfig.width= '400px';
        dialogConfig.data = {project: 'test', users: this.user};
         
        this.addOrgDialogRef = this.dialog.open(this.addOrgDialog,  dialogConfig);
    
      }

     addOrgSave(org){
       
      let data = {
        id: org.replace(/\s+/g, '-').toLowerCase(),
        displayName: org,
        createdOn: new Date(),
        createdBy: this.user.email,
        team: []
      }
      this.afs.collection('admin').doc("settings").collection('organizations').doc(data.id).set(data).then(() => {
        this.openSnackBar(data.displayName+ " was created", "X Close");
        this.addOrgDialogRef.close();
      }).catch(function(error) {   
        this.addOrgDialogRef.close();
        this.openSnackBar(error, "X Close");
    
      })
     }
    
     closeAddOrgDialog(): void {
      this.addOrgDialogRef.close();
      this.createdOrg = '';
    }
    
    
    removeOrg(orgs): void{
    
      const dialogConfig = new MatDialogConfig();
      dialogConfig.restoreFocus = false;
      dialogConfig.autoFocus = false;
      dialogConfig.role = 'dialog';
      dialogConfig.width= '400px';
      dialogConfig.data = {organizations: orgs, user: this.user};
       
      this.removeOrgDialogRef = this.dialog.open(this.removeOrgDialog,  dialogConfig);
  
    }

   removeOrgSave(org){
    //update Users Org and move then to the general OU aka NONE
    if(org.team){
      org.team.forEach(user => {  
      this.afs.collection('users').doc(user.id).update( {
        organization: firestore.FieldValue.arrayRemove(org.id) })
      })
    }
    //remove the org from projects
    
    if(org.projects){
      org.projects.forEach(project => {  
        this.afs.collection('admin').doc("settings").collection('projects').doc(project).update( {
          orgs: firestore.FieldValue.arrayRemove(org.id)
        })
      })
    }
    // now remove the org
    this.afs.collection('admin').doc("settings").collection('organizations').doc(org.id).delete().then(() => {
       this.openSnackBar(org.displayName+ " has been deleted", "X Close");
       this.removeOrgDialogRef.close();
    }).catch(function(error) {   
      this.removeOrgDialogRef.close();
      this.openSnackBar(error, "X Close");
  
    })
   }
    
   closeremoveOrgDialog(): void {
    this.removeOrgDialogRef.close();
    this.selectedOrg = '';
  }

  changeOrg(orgs, user): void{
        
        const dialogConfig = new MatDialogConfig();
        dialogConfig.restoreFocus = false;
        dialogConfig.autoFocus = false;
        dialogConfig.role = 'dialog';
        dialogConfig.width= '400px';
        dialogConfig.data = {organizations: orgs, user: user}; 
        this.changeOrgDialogRef = this.dialog.open(this.changeOrgDialog,  dialogConfig);
    
      }

     changeOrgSave(org, user){

      // add the user to the new org
      this.afs.collection('users').doc(user.uid).update( {
        organization:  firestore.FieldValue.arrayUnion(org.id)
       }).then(() => {
        //  remove the user from the old organization
      /*  if(user.organization){
          this.afs.collection('admin').doc("settings").collection('organizations').doc(user.organization).update( {
            team: firestore.FieldValue.arrayRemove({email:user.email, id:user.uid})
          })
        }  */
        //save the user record in the new organization
        this.afs.collection('admin').doc("settings").collection('organizations').doc(org.id).update( {
          team: firestore.FieldValue.arrayUnion({email:user.email, id:user.uid})
        }).then(data => {
         this.openSnackBar(user.displayName+ " has been moved to "+org.displayName, "X Close");
         this.closechangeOrgDialog();
        })
      }).catch(function(error) {   
        this.closechangeOrgDialog();
        this.openSnackBar(error, "X Close");
    
      })
     }
    
     closechangeOrgDialog(): void {
      this.changeOrgDialogRef.close();
      this.selectedOrg = '';
    }
    
  remOrg(org, user){
    // remove user from org
    this.afs.collection('users').doc(user.uid).update( {
      organization: firestore.FieldValue.arrayRemove(org)
    }).then(() => {
      //remove the user record from the organization
      this.afs.collection('admin').doc("settings").collection('organizations').doc(org).update( {
        team: firestore.FieldValue.arrayRemove({email:user.email, id:user.uid})
      }).then(data => {
       this.openSnackBar(user.displayName+ " has been moved to "+org, "X Close");
      })
    }).catch(function(error) {   
      this.openSnackBar(error, "X Close");
    })
  }  

  addOrgtoProject(index): void {
    let orgs = this.organizations;
    const dialogConfig = new MatDialogConfig();
    dialogConfig.restoreFocus = false;
    dialogConfig.autoFocus = false;
    dialogConfig.role = 'dialog';
    dialogConfig.data = {project: this.expandedElement, organizations: orgs, index:index};
    this.addOrgtoProjectDialogRef = this.dialog.open(this.addOrgtoProjectDialog,  dialogConfig);

  }

 addOrgtoProjectSave(org, p, index){
      //add the project record to the Org
    this.afs.collection('admin').doc("settings").collection('organizations').doc(org.id).update( {
     projects: firestore.FieldValue.arrayUnion(p.id)
   }).then(() => {
    // add the organization record to the project
      this.afs.collection('admin').doc("settings").collection('projects').doc(p.id).update( {
      orgs: firestore.FieldValue.arrayUnion(org.id)
    }).then(data => {
        if (p.orgs){
          p.orgs.push(org)
        }else{
          p.orgs = [];
          p.orgs.push(org)
        }
        this.openSnackBar(org.displayName+ " was added to " +p.name, "X Close");
        this.expandedElement = this.expandedElement.id === this.projectsSource.data[index].id ? this.projectsSource.data[index] : this.expandedElement;
        this.closeaddOrgtoProjectDialog();
      })
    }).catch(function(error) {
    this.closeaddOrgtoProjectDialog();
    this.openSnackBar(error, "X Close");
 
   })
}
 

 closeaddOrgtoProjectDialog() {
  this.addOrgtoProjectDialogRef.close();
  this.selectedOrg = '';
}

 archiveProject(project, index){
   this.afs.collection('admin').doc('settings').collection('projects').doc(project.id).update({
     status: 'archived'
   }).then( done =>{
    project.status = 'archived';
  //  this.expandedElement = this.expandedElement.id === this.projectsSource.data[index].id ? this.projectsSource.data[index] : this.expandedElement;
    this.openSnackBar('The project "'+project.name+'" has been archived', 'X Close');
   }).catch(error => {
    this.openSnackBar('Error: '+error, 'X Close');
   })
 }

 
 unarchiveProject(project, index){
  this.afs.collection('admin').doc('settings').collection('projects').doc(project.id).update({
    status: 'active'
  }).then( done =>{
    project.status = 'active';
   // this.expandedElement = this.expandedElement.id === this.projectsSource.data[index].id ? this.projectsSource.data[index] : this.expandedElement;
    this.openSnackBar('The project "'+project.name+'" has been unarchived', 'X Close');
   }).catch(error => {
    this.openSnackBar('Error: '+error, 'X Close');
   })
}

  removeUserProject(u, e, index){
    this.afs.collection('admin').doc("settings").collection('projects').doc(e.id).update( {
       team: firestore.FieldValue.arrayRemove(u.email)
     }).then(() => {

      for(var i = e.team.length - 1; i >= 0; i--) {
        if(e.team[i] === u) {
          e.team.splice(i, 1);
        }
      }
      this.openSnackBar(u.displayName+ " was removed from " +e.name, "X Close");
      //this.expandedElement = this.expandedElement.id === this.projectsSource.data[index].id ? this.projectsSource.data[index] : this.expandedElement;
   }).catch(function(error) {
     this.openSnackBar(error, "X Close");
   })

  }

  removeOrgProject(org, p, index){
    this.afs.collection('admin').doc("settings").collection('projects').doc(p.id).update( {
       orgs: firestore.FieldValue.arrayRemove(org.id)
     }).then(() => {

      for(var i = p.orgs.length - 1; i >= 0; i--) {
        if(p.orgs[i] === org) {
          p.orgs.splice(i, 1);
        }
      }
      this.openSnackBar(org.displayName+ " was removed from " +p.name, "X Close");
     // this.expandedElement = this.expandedElement.id === this.projectsSource.data[index].id ? this.projectsSource.data[index] : this.expandedElement;
   }).catch(function(error) {
     this.openSnackBar(error, "X Close");
   })
  
  }


  applyFilterOrgs(event) {
    if(event.id == 'all-system-users'){
     this.adminUsers.filter = '';
    }else{
     let filterValue = event.id;
     this.adminUsers.filter = filterValue.trim().toLowerCase();
    }
  }

  projectsFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.projectsSource.filter = filterValue.trim().toLowerCase();
  }

  ngOnInit(): void {
    this.transactions.paginator = this.transactionPaginator;
    this.sheetSource.paginator = this.sheetPaginator;
    this.sheetSource.sort = this.sheetSort;
    this.sheetSourcePending.paginator = this.sheetPendingPaginator;
    this.sheetSourcePending.sort = this.sheetPendingSort;

    
    this.detailDataSource.paginator = this.detailPaginator;
    this.detailDataSource.sort = this.detailSort;
    
    this.projectsSource.paginator = this.projectsPaginator;

    this.adminUsers.paginator = this.usersPaginator;

    
    this.paymentsDataSource.paginator = this.paymentsPaginator;
    this.paymentsDataSource.sort = this.paymentsSort;
  }

}
